<template>
  <div class="operationPop" ref="pop" v-if="operationList.length > 0">
    <el-popover
      placement="top-start"
      trigger="hover"
      popper-class="operation-pop"
    >
      <div
        class="pop"
        @mouseleave="leave"
        v-if="data.status !== 0 && data.status !== null"
      >
        <ul>
          <li
            class="el-cascader-node"
            v-for="(item, key) in operationList"
            @mouseenter="operationEnter(item.actionKey)"
            :key="key"
          >
            <span class="el-cascader-node__label">{{ item.name }}</span>
            <i
              v-show="item.children"
              class="el-icon-arrow-right el-cascader-node__postfix"
            ></i>
          </li>
        </ul>
        <!-- 二级菜单 暂时用不上 -->
        <ul>
          <li
            class="el-cascader-node"
            v-for="(item, key) in childOperationList"
            @click="handleNodeClick(item)"
            :key="key"
          >
            <span class="el-cascader-node__label">{{ item.name }}</span>
          </li>
        </ul>
      </div>

      <i
        class="iconfont its_icon_peizhi"
        :class="
          data.status !== 0 && data.status !== null ? 'active' : 'carOutline'
        "
        slot="reference"
        ref="icon"
      ></i>
    </el-popover>
    <!--操作列表-->
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="showDialog"
      :width="dialoWidth"
      v-if="showDialog"
      :key="DialogIndex"
      append-to-body
      :custom-class="DialogIndex == 'addVehicle' ? 'add-vehicle-dialog' : ''"
    >
      <!--文本信息下发  -->
      <send-information
        v-if="DialogIndex == 'sendInformation'"
        :data="data"
        @close="handleCloseDialog"
      ></send-information>
      <!-- 出租车-下发驾驶员信息 -->
      <send-driver
        v-if="DialogIndex == 'sendDrivers'"
        :data="data"
        @close="handleCloseDialog"
      ></send-driver>
      <!-- 设置终端参数 区分gps和出租车 deviceType==1出租车 -->
      <terminal-parametergps
        v-if="DialogIndex == 'terminalParameter' && deviceType !== '1'"
        :data="data"
        :settingValue="settingValue"
        @close="handleCloseDialog"
      ></terminal-parametergps>
      <terminal-parameter
        v-if="DialogIndex == 'terminalParameter' && deviceType == '1'"
        :data="data"
        @close="handleCloseDialog"
      ></terminal-parameter>
      <!-- 设置出租车服务器参数 -->
      <terminal-taxi-ip
        v-if="DialogIndex == 'terminalTaxiIp' && deviceType == '1'"
        :data="data"
        @close="handleCloseDialog"
      ></terminal-taxi-ip>
      <!-- 设置服务器参数 -->
      <terminal-ip
        v-if="DialogIndex == 'terminalIp'"
        :data="data"
        :settingValue="settingValue"
        @close="handleCloseDialog"
      ></terminal-ip>
      <!-- 设置IC卡参数 -->
      <make-card
        v-if="DialogIndex == 'ICardset'"
        :data="data"
        @close="handleCloseDialog"
      ></make-card>
      <!-- 报警屏蔽 -->
      <setAlarmShield
        :settingValue="settingValue"
        v-if="
          (DialogIndex == 'setAlarmShield' || DialogIndex === 'closeVoice') &&
            deviceType === '1'
        "
        :type="DialogIndex == 'setAlarmShield' ? 0 : 1"
        :id="data"
        :vehicleInfos="vehicleInfos"
        :terminalNo="telNos"
        @close="handleCloseDialog"
      ></setAlarmShield>
      <set-alarmShieldGps
        :settingValue="settingValue"
        v-if="
          (DialogIndex == 'setAlarmShield' || DialogIndex === 'closeVoice') &&
            deviceType !== '1'
        "
        :type="DialogIndex == 'setAlarmShield' ? 0 : 1"
        :id="data"
        :vehicleInfos="vehicleInfos"
        :terminalNo="telNos"
        @close="handleCloseDialog"
      ></set-alarmShieldGps>
      <!-- 监听功能 -->
      <listening
        v-if="DialogIndex == 'listening'"
        :settingValue="settingValue"
        @close="handleCloseDialog"
      ></listening>
      <!-- 高级参数设置 -->
      <advancedParameters
        v-if="DialogIndex == 'advancedParameters'"
        :settingValue="settingValue"
        :data="data"
        @close="handleCloseDialog"
      ></advancedParameters>
      <!-- 高级参数设置 -->
      <!--区域围栏设置 -->
      <area-alarmSetting
        v-if="DialogIndex == 'areaAlarmSetting'"
        :data="data"
        @close="handleCloseDialog"
      ></area-alarmSetting>
      <!--区域围栏设置 -->
      <!-- 查询车辆行驶记录 -->
      <queryCarDrivingRecords
        v-if="DialogIndex == 'queryCarDrivingRecords'"
        :data="data"
        @close="handleCloseDialog"
      ></queryCarDrivingRecords>
      <!-- 下线时限配置 -->
      <div class="body" v-if="DialogIndex == 'carOfflineTime'">
        <el-form
          ref="addForm"
          :model="addForm"
          size="small"
          label-position="left"
          class="demo-form-inline dialog-form"
        >
          <!-- 已选车辆终端 -->
          <el-form-item label="车牌号：" label-width="92px">
            <el-row>
              <el-col :span="12">{{ data.name }}</el-col>
              <el-col :span="12">终端号：{{ formatTelNo(data.videos) }}</el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="下线时限(分钟)：" label-width="140px">
            <el-input-number
              v-model="addForm.time"
              placeholder="请输入"
              :min="0"
              controls-position="right"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="长时间离线(分钟)：" label-width="140px">
            <el-input-number
              v-model="addForm.longTime"
              placeholder="请输入"
              :min="0"
              controls-position="right"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="    " style="text-align: center">
            <br />
            <el-button size="small" @click="showDialog = false">取消</el-button>
            <el-button type="primary" size="small" @click="showDialog = false"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- 设置运营线路 -->
      <div class="body" v-if="DialogIndex == 'setOperationLine'">
        <el-form
          ref="form"
          :model="form"
          size="small"
          :rules="rules"
          label-position="left"
          label-width="92px"
          class="demo-form-inline dialog-form"
        >
          <!-- 已选车辆终端 -->
          <el-form-item label="车牌号：">
            <el-row>
              <el-col :span="12">{{ data.name }}</el-col>
              <el-col :span="12">终端号：{{ formatTelNo(data.videos) }}</el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="运营线路：" prop="operateLine">
            <el-input
              v-model="form.operateLine"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        v-if="DialogIndex == 'setOperationLine'"
      >
        <el-button type="default" size="small" @click="showDialog = false"
          >取消</el-button
        >
        <el-button type="primary" size="small" @click="saveOperateLine"
          >保存</el-button
        >
      </span>
      <!-- 设置运营线路 -->

      <!-- 终端升级 || 查询终端属性 -->
      <terminalUpgrade
        v-if="
          DialogIndex == 'terminalAttributes' ||
            DialogIndex == 'QueryAttributes'
        "
        :settingValue="settingValue"
        :data="data"
        :type="DialogIndex"
        @close="handleCloseDialog"
      ></terminalUpgrade>
      <!-- 查询驾驶员信息 -->
      <dirverInfo
        :driverInfo="driverInfo"
        v-if="DialogIndex == 'driverManage'"
        @close="handleCloseDialog"
      ></dirverInfo>

      <!-- 客运车辆禁行设置 -->
      <carDrivingHibit
        :data="data"
        v-if="DialogIndex == 'carDrivingHibit'"
        @close="handleCloseDialog"
      ></carDrivingHibit>
      <!-- 实时拍照指令 -->
      <realtimePictures
        :data="data"
        v-if="DialogIndex == 'realtimePictures'"
        @close="handleCloseDialog"
      ></realtimePictures>
      <!-- 人工确认解除报警 -->
      <removeAlarm
        :data="data"
        v-if="DialogIndex == 'removeAlarm'"
        @close="handleCloseDialog"
      ></removeAlarm>
      <!-- 修改车辆信息 -->
      <!-- 2023.10.12 田产品：综合监控页面修改车辆必填信息不可修改，只能在资源管理系统里面修改，直接禁掉，所用字段名：monitoringDisabled,此字段默认为false -->
      <addVehicle
        ref="addVehicle"
        :vehicleNo="vehicleNo"
        :rowData="rowData"
        :monitoringDisabled="true"
        v-if="DialogIndex == 'addVehicle'"
        @saveEnd="saveEnd"
        @onLoading="onLoading"
      ></addVehicle>
      <!-- 查询路网状态图 -->
      <roadNetworkStatePage
        :data="data"
        v-if="DialogIndex == 'roadNetworkState' && deviceType !== 1"
        :deviceNo="deviceNo"
      ></roadNetworkStatePage>
      <!-- 路网图2.0 -->
      <roadNetworkStatePage2
        :data="data"
        v-if="DialogIndex == 'roadNetworkState2' && deviceType !== 1"
        :deviceNo="deviceNo"
      ></roadNetworkStatePage2>
      <!-- 云台控制 -->
      <holderControl
        :data="data"
        v-if="DialogIndex == 'holderControl'"
        @close="handleCloseDialog"
      ></holderControl>
      <!-- 载重限高配置 -->
      <highLoadLimit
        :data="data"
        :settingValue="settingValue"
        v-if="DialogIndex == 'highLoadLimit'"
        @close="handleCloseDialog"
      ></highLoadLimit>
      <!-- 设置音视频参数 -->
      <setAudioAndVideo
        :data="data"
        :settingValue="settingValue"
        v-if="DialogIndex == 'setAudioAndVideo'"
        @close="handleCloseDialog"
      ></setAudioAndVideo>
      <!-- footer -->
      <span
        slot="footer"
        class="dialog-footer"
        v-if="DialogIndex == 'addVehicle'"
      >
        <el-button type="default" size="small" @click="showDialog = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="editLoading"
          @click="onSave"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/static/operationList";
// 部标设备指令
import sendInformation from "@/components/orderOperation/sendInformation.vue"; // 文本信息下发
import sendDriver from "@/components/orderOperation/sendDriver.vue"; // 出租车下发驾驶员信息
import terminalParameterGps from "@/components/orderOperation/terminalParameterGps.vue"; // 设置终端参数-gps
import terminalParameter from "@/components/orderOperation/terminalParameter.vue"; // 设置终端参数-taxi
import terminalTaxiIp from "@/components/orderOperation/terminalTaxiIp.vue"; // 设置出租车服务器参数
import terminalIP from "@/components/orderOperation/terminalIP.vue"; // 设置服务器参数---新增汇报策略等指令（gps和公交）
import makeCard from "@/components/orderOperation/makeCard.vue"; // 设置服务器参数
import terminalUpgrade from "@/components/orderOperation/terminalUpgrade.vue"; //终端升级
import dirverInfo from "@/components/orderOperation/dirverInfo.vue"; //查询驾驶员信息
import carDrivingHibit from "@/components/orderOperation/carDrivingHibit.vue"; //客运车辆禁行设置
import setAlarmShield from "@/components/orderOperation/setAlarmShield.vue"; //报警屏蔽-taxi
import setAlarmShieldGps from "@/components/orderOperation/setAlarmShieldGps.vue"; //报警屏蔽
import removeAlarm from "@/components/orderOperation/removeAlarm.vue"; //人工解除报警
import listening from "@/components/orderOperation/listening.vue"; //监听功能

import roadNetworkStatePage from "@/components/orderOperation/roadNetworkStateTable.vue"; //查询路网状态图
import roadNetworkStatePage2 from "@/components/orderOperation/roadNetworkStateTable2.vue"; //查询路网状态图
import realtimePictures from "@/components/orderOperation/realtimePictures.vue"; //实时拍照
import holderControl from "@/components/orderOperation/holderControl.vue"; //云台控制
import highLoadLimit from "@/components/orderOperation/highLoadLimit.vue"; //载重限高
import setAudioAndVideo from "@/components/orderOperation/setAudioAndVideo.vue"; //音视频参数设置

import addVehicle from "@/views/projectResourse/basicData/vehicleManagement/components/addVehicle.vue"; //修改车辆信息
import advancedParameters from "@/components/orderOperation/advancedParameters.vue"; //高级参数设置
import areaAlarmSetting from "@/components/orderOperation/areaAlarmSetting.vue"; //区域围栏报警设置
import queryCarDrivingRecords from "@/components/orderOperation/queryCarDrivingRecords"; //查询车辆行驶记录

import {
  queryDeviceByIds,
  getPostionVhiclePositions,
  deleteVehicleConfig,
  checkVideoStreams
} from "@/api/lib/refreshAlarm.js";
import {
  updateVehicleLine,
  queryDriver,
  getVehicleById,
  getInfoByDeviceNo
} from "@/api/lib/api.js";

import { returnVideoArr } from "@/common/utils/index";

export default {
  name: "pop",
  components: {
    "send-information": sendInformation,
    "send-driver": sendDriver, //出租车下发驾驶员信息
    "terminal-parametergps": terminalParameterGps,
    "terminal-parameter": terminalParameter,
    "terminal-taxi-ip": terminalTaxiIp,
    "terminal-ip": terminalIP,
    "make-card": makeCard,
    terminalUpgrade,
    dirverInfo,
    carDrivingHibit,
    addVehicle,
    setAlarmShield, //屏蔽报警-出租车
    setAlarmShieldGps, //屏蔽报警
    roadNetworkStatePage,
    roadNetworkStatePage2, //路网图2.0
    realtimePictures, //实时拍照
    holderControl, //云台控制
    removeAlarm, //人工确认解除报警
    highLoadLimit, //载重限高
    setAudioAndVideo, //设置音视频参数
    listening, //音频监听功能
    advancedParameters, //高级参数设置
    areaAlarmSetting, //区域围栏报警设置
    queryCarDrivingRecords
  },
  props: {
    data: {
      type: [String, Number, Object]
    }
  },
  data() {
    return {
      operationList: [], // 所有操作菜单
      showChildMenu: false,
      childOperationList: [],
      telNos: [],
      vehicleInfos: [],
      deviceType: "", // 协议类型
      title: "",
      showDialog: false,
      statusMap: [],
      settingValue: null,
      timeExit: null,
      addForm: {
        time: 10,
        longTime: 60
      },
      form: { operateLine: null },
      rules: {
        operateLine: [{ required: true, message: "请选择", trigger: "blur" }]
      },
      driverInfo: null,
      rowData: null,
      vehicleNo: null,
      editLoading: false,
      deviceNo: ""
    };
  },
  methods: {
    onAddChildNode() {
      this.$emit("nodeOperation", this.data);
    },
    operationEnter(index) {
      const list = this.operationList.find(item => index === item.actionKey);
      if (list) this.childOperationList = list.children;
    },
    leave(e) {
      this.childOperationList = [];
    },
    /** 根据权限获取可操作菜单 */
    getMenuCtrl() {
      this.operationList = [];
      const storeMenu = this.$store.state.menu.nowMenuList;
      let tempList = [];
      api.operationList.forEach(element => {
        if (storeMenu.indexOf(element.name) > -1) {
          tempList.push(element);
        }
      });
      //根据actionKey进行分类
      var map = {},
        dest = [];
      for (var i = 0; i < tempList.length; i++) {
        var ai = tempList[i];
        if (!map[ai.actionKey]) {
          dest.push({
            actionKey: ai.actionKey,
            name: ai.actionKey,
            children: [ai]
          });
          map[ai.actionKey] = ai;
        } else {
          for (var j = 0; j < dest.length; j++) {
            var dj = dest[j];
            if (dj.actionKey == ai.actionKey) {
              dj.children.push(ai);
              break;
            }
          }
        }
      }
      this.operationList = dest;
      // console.log('菜单权限列表：',this.operationList)
    },
    /** 点击操作菜单 */
    handleNodeClick(data) {
      console.log("查看每一项的数据：", data);
      const { name, index, type, width } = data;
      this.toOperationPage(name, index, type, width);
    },
    /** 点击操作菜单 */
    toOperationPage(name, index, type, width) {
      if (type === "order") {
        // 只是下发指令,没有具体页面的
        queryDeviceByIds({ ids: this.data.id, system: this.deviceType }).then(
          res => {
            if (res.code === 1000) {
              let vehicle = res.data[0];
              let deviceNo = returnVideoArr(vehicle.videos, true);
              this.telNos = [deviceNo];
              this.vehicleInfos = [`${vehicle.cph}#${vehicle.companyName}`];
              this.handleOrder(name, index);
            }
          }
        );
        return;
      } else if (index === "setAlarmShield" || index === "closeVoice") {
        let coefficient = index === "setAlarmShield" ? 0x0050 : 0x0051;
        // 查询终端报警屏蔽设置
        queryDeviceByIds({ ids: this.data.id, system: this.deviceType }).then(
          res => {
            if (res.code === 1000) {
              let vehicle = res.data[0];
              let deviceNo = returnVideoArr(vehicle.videos, true);
              this.telNos = [deviceNo];
              this.vehicleInfos = [`${vehicle.cph}#${vehicle.companyName}`];
              this.$yhsdp.GBQueryAlarmSeting(
                this.telNos,
                [coefficient],
                this.deviceType
              );
              this.statusMap = [
                {
                  time: new Date(),
                  width: width,
                  name: name,
                  index: index
                }
              ];
              //区分出租车和gps
              if (this.deviceType !== "1") {
                this.$yhsdp.AddRefreshCallBack(
                  this.$RefreshTypeEnum.GB_QueryAlarmSeting,
                  this.getAlarmSetingGps
                );
              } else {
                //taxi
                this.$yhsdp.AddRefreshCallBack(
                  this.$RefreshTypeEnum.GB_QueryAlarmSeting,
                  this.getAlarmSeting
                );
              }
              /** 获取查询终端参数报警屏蔽设置的状态 */
              this.getStatusInterval();
            }
          }
        );
        return;
      } else if (index === "terminalParameter" || index === "terminalIp") {
        //gps和公交车设置终端参数
        queryDeviceByIds({ ids: this.data.id, system: this.deviceType }).then(
          res => {
            if (res.code === 1000) {
              let vehicle = res.data[0];
              let deviceNo = returnVideoArr(vehicle.videos, true);
              this.telNos = [deviceNo];
              this.vehicleInfos = [`${vehicle.cph}#${vehicle.companyName}`];
              this.$yhsdp.GBQueryAlarmSeting(
                this.telNos,
                [0x0055, 0x005b, 0x0056],
                this.deviceType
              );
              this.statusMap = [
                {
                  time: new Date(),
                  width: width,
                  name: name,
                  index: index
                }
              ];
              this.$yhsdp.AddRefreshCallBack(
                this.$RefreshTypeEnum.GB_QueryAlarmSeting,
                this.getAlarmSetingAll
              );
              /** 获取查询终端参状态 */
              this.getStatusInterval();
            }
          }
        );
        return;
      } else if (index === "advancedParameters") {
        queryDeviceByIds({ ids: this.data.id, system: this.deviceType }).then(
          res => {
            if (res.code === 1000) {
              let vehicle = res.data[0];
              let deviceNo = returnVideoArr(vehicle.videos, true);
              this.telNos = [deviceNo];
              this.vehicleInfos = [`${vehicle.cph}#${vehicle.companyName}`];
              this.$yhsdp.GBQueryAlarmSeting(
                this.telNos,
                [0x0055, 0x005b, 0x0056],
                this.deviceType
              );
              this.statusMap = [
                {
                  time: new Date(),
                  width: width,
                  name: name,
                  index: index
                }
              ];
              this.$yhsdp.AddRefreshCallBack(
                this.$RefreshTypeEnum.GB_QueryAlarmSeting,
                this.getAlarmSetingAdvanced
              );
              /** 获取查询终端参状态 */
              this.getStatusInterval();
            }
          }
        );
        return;
      } else if (index === "highLoadLimit") {
        let coefficient = [0xf372, 0xf373];
        //查询终端参数---载重限高
        queryDeviceByIds({ ids: this.data.id, system: this.deviceType }).then(
          res => {
            if (res.code === 1000) {
              let vehicle = res.data[0];
              let deviceNo = returnVideoArr(vehicle.videos, true);
              this.telNos = [deviceNo];
              this.vehicleInfos = [`${vehicle.cph}#${vehicle.companyName}`];
              this.$yhsdp.GBQueryAlarmSeting(
                this.telNos,
                coefficient,
                this.deviceType
              );
              this.statusMap = [
                {
                  time: new Date(),
                  width: width,
                  name: name,
                  index: index
                }
              ];
              this.$yhsdp.AddRefreshCallBack(
                this.$RefreshTypeEnum.GB_QueryAlarmSetingLimit,
                this.getLimitHeightSeting
              );
              /** 获取查询终端参数载重限高的状态 */
              this.getStatusInterval();
            }
          }
        );
        return;
      } else if (index === "listening") {
        const data = {
          port: "9500",
          device: returnVideoArr(this.data.videos, true), //终端号
          channel: "1",
          plate: this.data.name,
          playTime: new Date(),
          port: "9500",
          url: "4022"
        };
        this.dialoWidth = width !== "" ? width : "60%";
        this.title = `${name} ${this.data.name}_1`;
        this.DialogIndex = index;
        this.settingValue = data;
        this.showDialog = true;
        return;
      } else if (index === "setAudioAndVideo") {
        let coefficient = [0x0075];
        //查询终端参数---音视频
        queryDeviceByIds({ ids: this.data.id, system: this.deviceType }).then(
          res => {
            if (res.code === 1000) {
              let vehicle = res.data[0];
              let deviceNo = returnVideoArr(vehicle.videos, true);
              this.telNos = [deviceNo];
              this.vehicleInfos = [`${vehicle.cph}#${vehicle.companyName}`];
              this.$yhsdp.GBQueryAlarmSeting(
                this.telNos,
                coefficient,
                this.deviceType
              );
              this.statusMap = [
                {
                  time: new Date(),
                  width: width,
                  name: name,
                  index: index
                }
              ];
              this.$yhsdp.AddRefreshCallBack(
                this.$RefreshTypeEnum.GB_QueryAlarmSetingAV,
                this.getAVSeting
              );
              /** 获取查询终端参数设置音视频参数的状态 */
              this.getStatusInterval();
            }
          }
        );
        return;
      } else if (
        index === "terminalAttributes" ||
        index === "QueryAttributes"
      ) {
        // 查询终端属性--终端升级
        queryDeviceByIds({ ids: this.data.id, system: this.deviceType }).then(
          res => {
            if (res.code === 1000) {
              let vehicle = res.data[0];
              let deviceNo = returnVideoArr(vehicle.videos, true);
              this.telNos = [deviceNo];
              this.vehicleInfos = [`${vehicle.cph}#${vehicle.companyName}`];
              this.$yhsdp.GBQueryAttributes(
                [this.telNos, this.vehicleInfos],
                this.deviceType
              );
              this.statusMap = [
                {
                  time: new Date(),
                  width: width,
                  name: name,
                  index: index
                }
              ];
              this.$yhsdp.AddRefreshCallBack(
                this.$RefreshTypeEnum.GB_QueryAttributes,
                this.getAttributesSeting
              );
              this.getStatusInterval();
            }
          }
        );
        return;
      } else if (index === "driverManage") {
        // 查看驾驶员
        queryDriver(parseInt(this.data.id.replace("v", ""))).then(res => {
          if (res.code === 1000) {
            if (!res.data) {
              this.$message.warning(
                "当前车辆无驾驶员登签或驾驶员信息未录入平台"
              );
            } else {
              this.driverInfo = { ...res.data };
              this.dialoWidth = width !== "" ? width : "60%";
              this.showDialog = true;
              this.title = name;
              this.DialogIndex = index;
            }
          }
        });
        return;
      } else if (index === "addVehicle") {
        // 修改车辆信息
        getVehicleById(parseInt(this.data.id.replace("v", ""))).then(res => {
          if (res.code === 1000) {
            if (!res.data) {
              this.$message.warning(res.msg);
            } else {
              this.rowData = { ...res.data };
              this.vehicleNo = res.data.vehicleNo;
              this.dialoWidth = width !== "" ? width : "60%";
              this.showDialog = true;
              this.title = name;
              this.DialogIndex = index;
            }
          }
        });
        return;
      } else if (index === "setOperationLine") {
        // 设置运营线路
        getPostionVhiclePositions({
          ids: this.data.id,
          system: this.deviceType,
          currentPage: 1,
          pageSize: 10
        }).then(res => {
          if (res.code === 1000) {
            if (res.data.list.length != 0 && res.data.list[0].line) {
              this.form.operateLine = res.data.list[0].line;
            } else {
              this.form.operateLine = null;
            }
            this.dialoWidth = width !== "" ? width : "60%";
            this.showDialog = true;
            this.title = name;
            this.DialogIndex = index;
          }
        });

        return;
      } else if (index === "clearLocaleSetting") {
        //清除区域设置(只是请求接口没有具体页面)
        const id = this.data.id.replace("v", "");
        deleteVehicleConfig({ vehId: id })
          .then(res => {
            if (res.code === 1000) {
              this.$message.success("清除成功!");
            }
          })
          .catch(err => {
            console.log(err);
          });
        return;
      }
      this.dialoWidth = width !== "" ? width : "60%";
      this.showDialog = true;
      this.title = name;
      this.DialogIndex = index;
    },
    /** 设置操作菜单界面,点击关闭   单个点名不显示指令信息 */
    handleCloseDialog(flag) {
      this.showDialog = false;
      if (!flag) {
        this.$emit("nodeOperation", "pushTableToAct");
      }
    },
    /** 直接下发指令不需要填写参数 */
    handleOrder(name, index) {
      let flag = false; // 点名，不去定位到指令信息
      switch (index) {
        case "deviceCall": // 点名
          this.$yhsdp.GBCalling(
            [this.telNos, this.vehicleInfos],
            this.deviceType
          );
          flag = true;
          this.$emit("nodeOperation", "carCalling", this.data); // 默认选中,显示

          break;
        case "QueryParameter": // 查询终端参数
          if (this.deviceType == 1) {
            this.$yhsdp.GBQueryParameters(
              [this.telNos, this.vehicleInfos],
              [
                0x0020,
                0x0021,
                0x0022,
                0x0023,
                0x0024,
                0x0025,
                0x0026,
                0x0027,
                0x0028,
                0x0029,
                0x002a,
                0x002b,
                0x002c,
                0x002d,
                0x002e,
                0x002f,
                0x0030,
                0x0055,
                0x0056,
                0x0057,
                0x0058,
                0x0059,
                0x005a,
                0x0001,
                0x0010,
                0x0011,
                0x0012,
                0x0013,
                0x0014,
                0x0015,
                0x0016,
                0x0017,
                0x0018,
                0x0019,
                0xf001, //夜间限速设置
                0x0075, //音视频参数设置
                0xf372, //载重
                0xf373, //限高
                0xf364, //驾驶员辅助参数
                0xf365, //驾驶员行为监测
                0xff00, //终端MAC地址
                0xff01, //制造商ID
                0xff02, //终端型号
                0xff03, //路网图基础信息
                0xff04 //载重基础信息
              ],
              this.deviceType
            );
          } else {
            this.$yhsdp.GBQueryParameters(
              [this.telNos, this.vehicleInfos],
              null,
              this.deviceType
            );
          }
          break;
        case "computedLock": //计价器加锁
          //计价器：昆博终端协议不同，需要判断处理
          getInfoByDeviceNo(this.telNos).then(res => {
            if (res.code === 1000) {
              if (res.data?.markId == 14) {
                //昆博
                this.$yhsdp.GBSetAlarmSetingKunBo(
                  [this.telNos, this.vehicleInfos],
                  [149, 48],
                  this.deviceType
                ); //48-加锁，49解锁
                this.$emit("nodeOperation", "pushTableToAct"); // 默认选中,显示
              } else {
                this.$yhsdp.GBSetAlarmSetingX(
                  [this.telNos, this.vehicleInfos],
                  2,
                  this.deviceType
                );
                this.$emit("nodeOperation", "pushTableToAct"); // 默认选中,显示
              }
            } else {
              this.$message.warning("暂无终端信息！");
            }
          });
          break;
        case "computedUnlock": //计价器解锁
          getInfoByDeviceNo(this.telNos).then(res => {
            if (res.code === 1000) {
              if (res.data?.markId == 14) {
                //昆博
                this.$yhsdp.GBSetAlarmSetingKunBo(
                  [this.telNos, this.vehicleInfos],
                  [149, 49],
                  this.deviceType
                ); //48-加锁，49解锁
                this.$emit("nodeOperation", "pushTableToAct"); // 默认选中,显示
              } else {
                this.$yhsdp.GBSetAlarmSetingX(
                  [this.telNos, this.vehicleInfos],
                  1,
                  this.deviceType
                );
                this.$emit("nodeOperation", "pushTableToAct"); // 默认选中,显示
              }
            } else {
              this.$message.warning("暂无终端信息！");
            }
          });

          break;
      }
      if (!flag) this.$emit("nodeOperation", "pushTableToAct");
    },
    /** 获取查询终端参数报警屏蔽设置的状态 */
    getStatusInterval() {
      const self = this;
      self.timeExit = setInterval(() => {
        if (self.statusMap.length === 0) return;

        const time = new Date().getTime() - self.statusMap[0].time.getTime(); // 毫秒
        if (time >= 15000) {
          self.statusMap = [];
          self.$message.warning("返回超时，请检查网络情况后再试！");
          clearInterval(self.timeExit);
        }
      }, 2000);
    },
    getAlarmSetingGps(res) {
      let self = this;
      if (res.length < 1) {
        this.$message.warning("该终端暂不支持设置");
        clearInterval(self.timeExit);
        return;
      }
      let { width, name, index } = self.statusMap[0];
      self.dialoWidth = width !== "" ? width : "60%";
      self.title = name;
      self.DialogIndex = index;
      let alarm = 0;
      alarm =
        index === "setAlarmShield"
          ? (res.find(item => item.id === 80) || {}).value || 0
          : (res.find(item => item.id === 81) || {}).value || 0;
      self.settingValue = alarm.toString(2).padStart(32, 0);
      self.statusMap = [];
      clearInterval(self.timeExit);
      self.showDialog = true;
    }, //gps
    getAlarmSeting(res) {
      let self = this;
      if (res.length < 1) {
        this.$message.warning("该终端暂不支持设置");
        clearInterval(self.timeExit);
        return;
      }
      let { width, name, index } = self.statusMap[0];
      self.dialoWidth = width !== "" ? width : "60%";
      self.title = name;
      self.DialogIndex = index;
      //兼容数据格式不统一
      if(Array.isArray(res) && index === "setAlarmShield"){
        res = (res.find(item => item.id === 80 || item.id ===81) || {});
      }
      self.settingValue = res.value.toString(2).padStart(29, 0);
      console.log("出租车屏蔽报警：",self.settingValue);
      self.statusMap = [];
      clearInterval(self.timeExit);
      self.showDialog = true;
    },
    //设置高级参数
    getAlarmSetingAdvanced(res) {
      console.log("*****高级", res); //兼容2019新增
      let self = this;
      let { width, name, index } = self.statusMap[0];
      if (index != "advancedParameters") return;
      if (res.length < 1) {
        this.$message.warning("该终端暂不支持设置高级参数");
        clearInterval(self.timeExit);
        return;
      }

      self.dialoWidth = width !== "" ? width : "60%";
      self.title = name;
      self.DialogIndex = index;
      // console.log('//////设置高级参数前参数查询：',res)
      let adasArr = []; //adas参数
      let dsmArr = []; //dsm参数
      let tireArr = []; //轮胎参数
      let blindArr = []; //盲区参数
      let fierceArr = []; //激烈驾驶
      dsmArr = (res.find(item => item.id === 62309) || {}).value || [];
      tireArr = (res.find(item => item.id === 62310) || {}).value || [];
      blindArr = (res.find(item => item.id === 62311) || {}).value || [];
      adasArr = (res.find(item => item.id === 62308) || {}).value || [];
      fierceArr = (res.find(item => item.id === 62320) || {}).value || [];
      this.settingValue = {
        adasArr,
        dsmArr,
        tireArr,
        blindArr,
        fierceArr
      };
      self.statusMap = [];
      clearInterval(self.timeExit);
      self.showDialog = true;
    },
    //设置终端参数
    getAlarmSetingAll(res) {
      // console.log('设置终端参数',res)
      let self = this;
      let { width, name, index } = self.statusMap[0];
      self.dialoWidth = width !== "" ? width : "60%";
      self.title = name;
      self.DialogIndex = index;
      //循环res匹配出来所需终端参数
      let speedLimit = null; //最高速度门限(km/h)
      let preSpeeding = null; //预超速提前量(km/h)
      let time = null; //超速持续时间
      let isOpenNight = null; //夜间禁行开关
      let nightSpeedLimitPer = null; //夜间限速比例
      let period = null; //夜间限速时段
      //运营汇报策略
      let report = ""; //位置汇报策略
      let plan = ""; //位置汇报方案
      let noReportTime = ""; //缺省汇报时间间隔（秒）
      let dormancyReportTime = ""; //休眠汇报时间间隔（秒）
      let noReportDistance = ""; //缺省汇报距离间隔（米）
      let dormancyReportDistance = ""; //休眠汇报距离间隔（米）
      let noSignTime = ""; //司机未登签汇报间隔（秒）
      let noSignDistance = ""; //司机未登签距离间隔（米）
      res.forEach(item => {
        item.id === 85 && (speedLimit = item.value); //最高速度门限(km/h)
        item.id === 91 && (preSpeeding = item.value); //预超速提前量(km/h)
        item.id === 86 && (time = item.value); //超速持续时间

        if (item.id === 61441) {
          //夜间限速设置
          isOpenNight = item.value[0]; //开关
          nightSpeedLimitPer = item.value[1]; //夜间限速比例
          period = [item.value[2], item.value[3]]; //夜间限速时段
        }
        //服务器设置参数
        item.id === 32 && (report = item.value);
        item.id === 33 && (plan = item.value);
        item.id === 41 && (noReportTime = item.value);
        item.id === 39 && (dormancyReportTime = item.value);
        item.id === 44 && (noReportDistance = item.value);
        item.id === 46 && (dormancyReportDistance = item.value);
        item.id === 34 && (noSignTime = item.value);
        item.id === 45 && (noSignDistance = item.value);
      });
      self.settingValue = {
        //超速报警+限速
        speedLimit,
        preSpeeding,
        time,
        isOpenNight,
        nightSpeedLimitPer,
        period,
        report, //运营汇报策略
        plan,
        noReportTime,
        dormancyReportTime,
        noReportDistance,
        dormancyReportDistance,
        noSignTime,
        noSignDistance
      };
      // console.log('6666',self.settingValue)
      self.statusMap = [];
      clearInterval(self.timeExit);
      self.showDialog = true;
    },
    getAttributesSeting(res) {
      const self = this;
      if (self.statusMap.length === 0) return;
      const { width, name, index } = self.statusMap[0];
      self.dialoWidth = width !== "" ? width : "60%";
      self.title = name;
      self.DialogIndex = index;
      self.settingValue = res.result;
      self.settingValue.telNos = self.telNos;
      self.settingValue.vehicleInfos = self.vehicleInfos;
      self.statusMap = [];
      clearInterval(self.timeExit);
      self.showDialog = true;
    },
    //获取载重限高参数值
    getLimitHeightSeting(res) {
      const self = this;
      if (self.statusMap.length === 0) return;
      const { width, name, index } = self.statusMap[0];
      if (index != "highLoadLimit") return;
      if (res.length < 1) {
        this.$message.warning("该终端暂不支持设置载重限高");
        clearInterval(self.timeExit);
        return;
      }
      self.dialoWidth = width !== "" ? width : "60%";
      self.title = name;
      self.DialogIndex = index;
      self.settingValue = {
        totalQuality: res[0].value.split("*")[0], //车辆限定总质量
        maxTorque: res[1].value.split("牛")[0], //发动机最大扭矩
        speedMsgID1: res[2].value, //车速1CAN报文ID
        speedMsgID2: res[3].value, //车速2CAN报文ID
        direction: res[4].value, //检测设备安装方向
        period: res[5].value.split("*")[0], //载重计算周期
        coefficient: res[6].value, //设置计算滤波系数
        upSpeedInitial: res[7].value, //加速度初始值
        height: res[8].value.split("毫")[0], //车身高度
        width: res[9].value.split("毫")[0] //车身宽度
      };
      // console.log('settingValue',this.settingValue)
      self.statusMap = [];
      clearInterval(self.timeExit);
      self.showDialog = true;
    },
    //音视频参数设置
    getAVSeting(res) {
      const self = this;
      if (self.statusMap.length === 0) return;
      const { width, name, index } = self.statusMap[0];
      if (index != "setAudioAndVideo") return;
      if (res.length < 1) {
        this.$message.warning("该终端暂不支持设置音视频参数");
        clearInterval(self.timeExit);
        return;
      }
      self.dialoWidth = width !== "" ? width : "60%";
      self.title = name;
      self.DialogIndex = index;
      //二进制转换
      let bfArr = Number(res[10]);
      let bit2 = bfArr
        .toString(2)
        .split("")
        .reverse();
      let l1 = bit2.length;
      bit2.length = 16;
      let bit16 = bit2.fill("0", l1, 16);
      let nameArr = [];
      let bitText = [];
      let arrList = [
        { value: 0, label: "日期和时间" },
        { value: 1, label: "车牌号码" },
        { value: 2, label: "逻辑通道号" },
        { value: 3, label: "经纬度" },
        { value: 4, label: "行驶记录速度" },
        { value: 5, label: "卫星定位速度" },
        { value: 6, label: "连续驾驶时间" }
      ];
      bit16.forEach((item, ind) => {
        if (item == "1") {
          let current =
            arrList.find(temp => temp.value === Number(ind)) || false;
          current && nameArr.push(current);
        }
      });
      nameArr.map(item => {
        bitText.push(item.value);
      });
      self.settingValue = {
        encodeMode: res[0], //实时流编码模式
        resolutionRatio: res[1], //实时流分辨率
        realInterval: res[2], //实时流关键帧间隔
        targetFrame: res[3], //实时流目标帧率
        targetCode: res[4], //实时流目标码率
        saveEncodeMode: res[5], //存储流编码模式
        saveResolutionRatio: res[6], //存储流分辨率
        saveInterval: res[7], //存储流关键帧间隔
        saveTargetFrame: res[8], //存储流目标帧率
        savetargetCode: res[9], //存储流目标码率
        isAVOpen: res[11], //是否启用音视频输出
        osdConfig: bitText //OSD字幕叠加设置
      };
      self.statusMap = [];
      clearInterval(self.timeExit);
      self.showDialog = true;
    },
    //获取telno
    formatTelNo(videos) {
      return videos ? returnVideoArr(videos, true) : "";
    },
    //设置营运线路
    saveOperateLine() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let data = {
            id: parseInt(this.data.id.replace("v", "")),
            operateLine: this.form.operateLine
          };
          updateVehicleLine(data).then(res => {
            if (res.code === 1000) {
              this.$message.success("修改成功");
              this.showDialog = false;
            } else {
              this.$message.warning(res.msg);
            }
          });
        }
      });
    },
    onSave() {
      this.$refs.addVehicle.onSave();
    },
    saveEnd() {
      this.showDialog = false;
    },
    onLoading(flag) {
      this.editLoading = flag;
    }
  },
  created() {
    this.getMenuCtrl();
    // console.log('指令能拿到的参数',this.data)
  },
  mounted() {
    this.deviceNo = this.formatTelNo(this.data.videos);
    this.deviceType = sessionStorage.getItem("system");
  },
  destroyed() {
    clearInterval(self.timeExit);
    this.$yhsdp.RemoveRefreshCallBack(
      this.$RefreshTypeEnum.GB_QueryAttributes,
      this.getAttributesSeting
    );
  }
};
</script>

<style lang="scss" scoped>
.operationPop {
  display: inline-block;
  @include themify() {
    .active {
      color: themed("b4");
    }
    .carOutline {
      color: #dcdedf;
    }
  }
}
</style>
