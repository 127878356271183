/*
create by gw : 2018-10-17  用于创建于后台通信并且解析其回传的数据，同时存储解析后的数据对象

在整个项目中，只需要在根页面产生该对象即可

*/

import { GpsReceiveData } from "./gpsReceiveData.js";
import {
  YHTools,
  RefreshTypeEnum,
  parametersKey2,
  alarmShieldGps,
  alarmShield
} from "./Tools.js";
import parametersKey from "./parameters.json";
import { oneGpsToLngLat } from "@/common/utils/index";
import getPlace from "@/common/utils/getPlace.js";
import baseConfig from "@/api/static/config";
//返回结果是多个，需合并处理
let driveSpeedRes = [];
let drivePositionRes = [];
let accidentsRes = [];
let driveDriverRecordRes = [];
let drivePowerRecordRes = [];
let driveSpeedStatusRes = [];

// 数据刷新回调对象定义    回调对象,   回调函数
export class RefreshCallBack {
  constructor(callObject = null, callbackFunc = null) {
    // 没有具体的回调对象需要传的话，这里可以传null
    this.CallObject = callObject;
    // 请求回调函数（参数为对象,具体类型的具体定义见：REFRESH_CALLBACK_DEFINE）
    this.CallBackFunc = callbackFunc;
  }

  DoCallBackFunc(data) {
    try {
      const obj = this.CallObject;
      const func = this.CallBackFunc;
      if (obj != null) {
        obj.func(data);
      } else {
        func(data);
      }
    } catch (e) {
      // TODO handle the exception
      console.error("yh_sdp  RefreshCallBack  DoCallBackFunc " + e.message);
    }
  }
}

/**
 * purpose : 用于创建于后台通信并且解析其回传的数据，同时存储解析后的数据对象
 * callBack_ReturnCnnText 连接状态信息回调  参数(string sReturnText, int CnnStatus)  //CnnStatus 0数据失败 1正在交互数据 2交互数据完成
 */
export class yh_sdp {
  /**
   * 创建yh_sdp对象
   * @param {*} sMainSrcIp 连接websocket 地址ip
   * @param {*} userId  账号id
   * @param {*} userName 账号名称
   * @param {*} loginIp 账号登录ip 唯一识别客户端
   * @param {*} callBack_ReturnCnnText
   * 连接状态回调函数   回调函数参数( sReturnText, iCnnStatus)   (iCnnStatus为2时表示登录成功,999表示websocket连接关闭,888表示websocket连接报错)
   */
  constructor(sMainSrcIp, userId, userName, loginIp, callBackReturnCnnText) {
    // 接收数据回调
    this.thisReceive = new GpsReceiveData(
      sMainSrcIp,
      userId,
      userName,
      loginIp,
      this,
      this.GpsAddDataPacket,
      this.GpsInteractionDataOut,
      this.ReturnCnnText,
      this.GpsReturnDownLoadList
    );

    // 返回状态信息
    this.ReturnCnnTextCallBack = callBackReturnCnnText;

    // 回调函数集合 key: RefreshTypeEnum     value: list[RefreshCallBack ]
    this.RefreshCallFunctionList = new Map();

    // 是否通过了登陆界面的账号密码登陆验证
    this.bIsPassLogin = false;
  }

  /* -------------------------------------------------------------刷新数据相关流程操作-------------------------------------------------- */
  /**
   * 添加数据刷新回调
   * @param {RefreshTypeEnum} eRefreshType 数据的类型
   * @param {RefreshTypeEnum} callFunc 回调函数
   * @param {object} callObject 回调对象
   */
  AddRefreshCallBack(eRefreshType, callFunc, callObject = null) {
    const eobjl = [];
    eobjl.push(new RefreshCallBack(callObject, callFunc));
    this.RefreshCallFunctionList.set(eRefreshType, eobjl);
    // let eobjl = this.RefreshCallFunctionList.get(eRefreshType);
    // if (eobjl != null) {
    // 	eobjl.push(new RefreshCallBack(callObject, callFunc));
    // } else {
    // 	eobjl = [];
    // 	eobjl.push(new RefreshCallBack(callObject, callFunc));
    // 	this.RefreshCallFunctionList.set(eRefreshType, eobjl);
    // }
  }

  /**
   *  执行数据刷新回调
   * @param {RefreshTypeEnum} eRefreshType 数据的类型
   * @param {object} data 数据
   */
  RefreshDataBack(eRefreshType, data) {
    // console.log('yh_查岗回调',eRefreshType, data)//263 不存在
    const eobjl = this.RefreshCallFunctionList.get(eRefreshType);
    if (eRefreshType === 513) {
    }
    if (eobjl != null) {
      for (let i = 0; i < eobjl.length; ++i) {
        eobjl[i].DoCallBackFunc(data);
        // debugger
      }
    }
  }

  /**
   * 删除数据刷新回调
   * @param {RefreshTypeEnum} eRefreshType eRefreshType 数据的类型
   * @param {Function} callFunc 回调函数
   */
  RemoveRefreshCallBack(eRefreshType, callFunc) {
    const eobjl = this.RefreshCallFunctionList.get(eRefreshType);
    if (eobjl != null) {
      for (let i = 0; i < eobjl.length; ++i) {
        if (eobjl[i].CallBackFunc === callFunc) {
          eobjl.slice(i, 1);
        }
      }
    }
  }
  /* ---------------------------------------------------------------------------中心转发数据数据交互------------------------------------------------------------------------------ */

  /* -------------------------------------------------------------具体的中心转发数据解析函数-------------------------------------------------- */

  /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * 连接状态信息回调
   * @param {*} callObject 回调对象
   * @param {*} sReturnText 回调对象
   * @param {*} iCnnStatus 0-登陆失败  1-正在登陆  2-登陆成功  3-下载资料  888-服务器错误   999-服务器连接关闭
   */
  ReturnCnnText(callObject, sReturnText, iCnnStatus) {
    try {
      if (!callObject.bIsPassLogin && iCnnStatus === 2) {
        callObject.bIsPassLogin = true;
      }

      // by gw 2018-10-19  后续看是不是需要把这个函数添加上
      if (callObject.ReturnCnnTextCallBack != null) {
        callObject.ReturnCnnTextCallBack(sReturnText, iCnnStatus);
      }
    } catch (e) {
      // TODO handle the exception
      console.error("yh_sdp  ReturnCnnText  error: " + e.message);
    }
  }

  /**
   *  gps服务器——交互信息回调(下发)
   * @param {*} callObject 回调对象
   * @param {*} sVehicleNo 车牌号码
   * @param {*} vehGroupName 车辆所属公司
   * @param {*} sTime 时间
   * @param {*} sCmdTxt 命令
   * @param {*} orderId 指令id
   * @param {*} terminalNo 终端号
   */
  GpsInteractionDataOut(
    callObject,
    sVehicleNo,
    vehGroupName,
    sTime,
    sCmdTxt,
    orderId,
    terminalNo
  ) {
    try {
      if (callObject.bIsPassLogin) {
        const data = {
          type: 0,
          param: {
            orderId: orderId,
            terminalNo: terminalNo,
            sVehNo: sVehicleNo,
            vehGroupName: vehGroupName,
            sTime: sTime,
            sCmdTxt: sCmdTxt
          }
        };
        callObject.RefreshDataBack(
          RefreshTypeEnum.GPS_InteractionDataOut,
          data
        );
      }
    } catch (e) {
      // TODO handle the exception
      console.error("yh_sdp  GpsInteractionDataOut  error: " + e.message);
    }
  }

  /**
   * 指令信息回应——回调(终端回应)
   * @param {*} thisData 参数
   * @param {*} text   参数
   */
  GpsInteractionDataCallback(thisData, text) {
    // console.log('拿到websocket返回的数据解析展示在页面的结果',thisData, text)
    try {
      if (this.bIsPassLogin) {
        const timeTemp = YHTools.DateFormat(new Date(), "yyyy-MM-dd HH:mm:ss");
        const { webFlowId, terminalPhoneNo } = thisData;
        const data = {
          type: 1,
          param: {
            orderId: webFlowId,
            terminalNo: terminalPhoneNo,
            sTime: timeTemp,
            sNote: text
          }
        };
        this.RefreshDataBack(RefreshTypeEnum.GPS_InteractionDataOut, data);
      }
    } catch (e) {
      // TODO handle the exception
      console.error("yh_sdp  GpsInteractionDataOut  error: " + e.message);
    }
  }
  /**实时视频数据更改 */
  GpsInteractionDataCallbackReal(thisData, text, id, commandFlag) {
    // console.log('拿到websocket返回的数据解析展示在页面的结果',thisData, text)
    try {
      if (this.bIsPassLogin) {
        const timeTemp = YHTools.DateFormat(new Date(), "yyyy-MM-dd HH:mm:ss");
        const { webFlowId, terminalPhoneNo } = thisData;
        const data = {
          type: 1,
          param: {
            orderId: webFlowId,
            terminalNo: terminalPhoneNo,
            sTime: timeTemp,
            sNote: text,
            imgId: id,
            commandFlag: commandFlag
          }
        };
        this.RefreshDataBack(RefreshTypeEnum.GPS_InteractionDataOut, data);
      }
    } catch (e) {
      // TODO handle the exception
      console.error("yh_sdp  GpsInteractionDataOut  error: " + e.message);
    }
  }
  /**
   * 下发指令——查询信息返回回调
   * @param {*} callObject 回调对象
   * @param {*} data 车辆参数
   */
  GPSReturnOfCommand(callObject, data) {
    try {
      if (callObject.bIsPassLogin) {
        callObject.RefreshDataBack(RefreshTypeEnum.GPS_ReturnOfCommand, {
          data: data
        });
      }
    } catch (e) {
      // TODO handle the exception
      console.error("yh_sdp  GPSReturnOfCommand  error: " + e.message);
    }
  }

  /**
   * 历史视频上传
   * @param {*} callObject 回调对象
   * @param {*} data 车辆参数
   */
  GpsReturnDownLoadList(callObject) {
    try {
      if (callObject.bIsPassLogin) {
        callObject.RefreshDataBack(RefreshTypeEnum.GPS_DownLoadHistoryVideo);
      }
    } catch (e) {
      // TODO handle the exception
      console.error("yh_sdp  GpsReturnDownLoadList  error: " + e.message);
    }
  }

  /**
   * gps服务器——数据返回回调函数
   * purpose----解析具体数据  然后给监听了刷新消息的页面进行通知（即调用回调函数）
   * @param {*} callObject
   * @param {*} dataType
   * @param {*} returnData
   * callBack_InteractionData 交互信息回调  参数(string sVehicleNo, string sTime, string sCmdTxt, string sNote)
   */
  GpsAddDataPacket(callObject, dataType, returnData) {
    // console.log('yh_接收到的数据返回函数',callObject, dataType, returnData)
    try {
      if (callObject.bIsPassLogin) {
        const thisData = JSON.parse(returnData).data;
        const terminalType = JSON.parse(returnData).terminalType;

        if (thisData != null) {
          switch (dataType) {
            case "0xE2": // 警报信息
              callObject.RefreshDataBack(RefreshTypeEnum.GPS_Alarm, returnData);
              break;
            case "0xF4": // 长时间离线通知
              callObject.RefreshDataBack(
                RefreshTypeEnum.GPS_LongOffline,
                returnData
              );
              break;
            case "0xF5": //用户抢占视频资源
              callObject.RefreshDataBack(RefreshTypeEnum.GB_Cash, thisData);
              break;
            case "0xE3": // 车辆拍照返回数据
              callObject.RefreshDataBack(RefreshTypeEnum.GPS_Image, returnData);
              break;
            case "0xE4": // 车辆上下线数据
              callObject.RefreshDataBack(RefreshTypeEnum.GPS_Vehicle, thisData);
              break;
            case "0xE5": // 提醒用户系统已更新
              callObject.RefreshDataBack(RefreshTypeEnum.GB_Remind, thisData);
              break;
            case "0xEC": // 获取上级平台的消息数据(获取)-查岗
              callObject.RefreshDataBack(
                RefreshTypeEnum.GPS_PlatformCommunication,
                returnData
              );
              break;
            case "0xF6": // 福建报警督办
              callObject.RefreshDataBack(
                RefreshTypeEnum.GPS_PlatformCommunication,
                returnData
              );
              break;
            case "0xF3": // 福建查岗
              callObject.RefreshDataBack(
                RefreshTypeEnum.GPS_PlatformCommunication,
                returnData
              );
              break;
            case "0xEB": // 收到报警督办信息
              callObject.RefreshDataBack(
                RefreshTypeEnum.GPS_AlarmSupervisionInformation,
                returnData
              );

              break;
            case "0xE7": // 下发指令
              const result = JSON.parse(thisData.responseData);
              const resultCode =
                typeof result.resultCode === "number" ? result.resultCode : 5;

              /** 下发指令通用应答 */
              switch (resultCode) {
                // 0:成功 1：失败 2：消息有误 3：不支持 4：报警处理确认
                case 0:
                  callObject.GpsInteractionDataCallback(thisData, "应答成功");
                  break;
                case 1:
                  callObject.GpsInteractionDataCallback(thisData, "应答失败");
                  break;
                case 2:
                  callObject.GpsInteractionDataCallback(
                    thisData,
                    "应答失败,消息有误"
                  );
                  break;
                case 3:
                  callObject.GpsInteractionDataCallback(thisData, "不支持");
                  break;
                case 4:
                  callObject.GpsInteractionDataCallback(
                    thisData,
                    "报警处理确认"
                  );
                  break;
                default:
                  break;
              }

              // 有需要返回数据显示的
              switch (thisData.command) {
                case 0x8700:
                  console.log("0x8700", result, thisData);
                  let text = "";
                  let dataInfo = "";
                  let commandFlag = false;
                  if (result.recorderVersion) {
                    text = `记录仪执行20${result.recorderVersion.year ||
                      "03"}年，修改单号${result.recorderVersion.changeNumber}`;
                  } else if (result.driverInfo) {
                    text = `机动车驾驶证号码：${result.driverInfo.driverCardNo}`;
                  } else if (result.terminalRealClock) {
                    text = `记录仪实时时间：${result.terminalRealClock.realTime}`;
                  } else if (result.driveMile) {
                    text = `记录仪实时时间：${result.driveMile.realTime}，记录仪初次安装时间：${result.driveMile.installTime}，初始里程：${result.driveMile.startMile}km，累计行驶里程：${result.driveMile.totalMile}km`;
                  } else if (result.vehicleFeature) {
                    text = `记录仪当前时间：${result.vehicleFeature.realTime}，记录仪脉冲系数：${result.vehicleFeature.pulseValue}`;
                  } else if (result.vehicleInfo) {
                    text = `车牌号：${result.vehicleInfo.vehicleNo}，机动车号牌分类：${result.vehicleInfo.vehicleClass}，车架号：${result.vehicleInfo.VIN}`;
                  } else if (result.signalConfig) {
                    text = `记录仪实时时间：${result.signalConfig.realTime}，状态信号名称：${result.signalConfig.singleName}，状态信号：${result.signalConfig.signalStatus}`;
                  } else if (result.uniqueNumber) {
                    text = `生产厂CCC认证代码：${result.uniqueNumber.ccc}，认证产品型号：${result.uniqueNumber.model}，记录仪生产日期：${result.uniqueNumber.productionTime}，产品生产流水号：${result.uniqueNumber.flow}`;
                  } else if (result.driveSpeed) {
                    driveSpeedRes.push(...result.driveSpeed);
                    driveSpeedRes.sort(
                      (a, b) =>
                        new Date(b.beginTime).valueOf() -
                        new Date(a.beginTime).valueOf()
                    );
                    text = driveSpeedRes.length
                      ? `开始时间：${
                          driveSpeedRes[driveSpeedRes.length - 1]?.beginTime
                        }，结束时间：${driveSpeedRes[0]?.beginTime.substring(
                          0,
                          driveSpeedRes[0]?.beginTime.length - 2
                        ) + "59"}`
                      : "无数据";
                    dataInfo = driveSpeedRes;
                    commandFlag = true;
                  } else if (result.drivePosition) {
                    drivePositionRes.push(...result.drivePosition);
                    drivePositionRes.sort(
                      (a, b) =>
                        new Date(b.beginTime).valueOf() -
                        new Date(a.beginTime).valueOf()
                    );
                    text = drivePositionRes.length
                      ? `开始时间：${
                          drivePositionRes[drivePositionRes.length - 1]
                            ?.beginTime
                        }`
                      : "无数据";
                    dataInfo = drivePositionRes;
                    commandFlag = true;
                  } else if (result.accidents) {
                    accidentsRes.push(...result.accidents);
                    accidentsRes.sort(
                      (a, b) =>
                        new Date(b.endTime).valueOf() -
                        new Date(a.endTime).valueOf()
                    );
                    text = accidentsRes.length
                      ? `结束时间：${accidentsRes[0]?.endTime}`
                      : "无数据";
                    dataInfo = accidentsRes;
                    commandFlag = true;
                  } else if (result.driveOverTime) {
                    text = result.driveOverTime.length
                      ? `开始时间：${result.driveOverTime[0]?.startTime}，
                      结束时间：${result.driveOverTime[0]?.endTime}，
                      机动车驾驶证号码：${result.driveOverTime[0]?.driverCardNo}，
                      开始位置信息：${result.driveOverTime[0]?.longitude1}，${result.driveOverTime[0]?.latitude1}，${result.driveOverTime[0]?.altitude1}，
                      结束位置信息：${result.driveOverTime[0]?.longitude2}，${result.driveOverTime[0]?.latitude2}，${result.driveOverTime[0]?.altitude2}`
                      : "无数据";
                  } else if (result.driveDriverRecord) {
                    driveDriverRecordRes.push(...result.driveDriverRecord);
                    driveDriverRecordRes.sort(
                      (a, b) =>
                        new Date(b.realTime).valueOf() -
                        new Date(a.realTime).valueOf()
                    );
                    text = driveDriverRecordRes.length
                      ? `发生时间：${driveDriverRecordRes[0]?.realTime}，机动车驾驶证号码：${driveDriverRecordRes[0]?.driverCardNo}，事件类型：${driveDriverRecordRes[0]?.typeStr}`
                      : "无数据";
                    dataInfo = driveDriverRecordRes;
                    commandFlag = true;
                  } else if (result.drivePowerRecord) {
                    drivePowerRecordRes.push(...result.drivePowerRecord);
                    drivePowerRecordRes.sort(
                      (a, b) =>
                        new Date(b.realTime).valueOf() -
                        new Date(a.realTime).valueOf()
                    );
                    text = drivePowerRecordRes.length
                      ? `发生时间：${drivePowerRecordRes[0]?.realTime}，事件类型：${drivePowerRecordRes[0]?.typeStr}`
                      : "无数据";
                    dataInfo = drivePowerRecordRes;
                    commandFlag = true;
                  } else if (result.driveSetRecord) {
                    const map = {
                      "82H": "设置车辆信息",
                      "83H": "设置记录仪初次安装日期",
                      "84H": "设置状态量配置信息",
                      C2H: "设置记录仪时间",
                      C3H: "设置记录仪脉冲系数",
                      C4: "设置初始里程"
                    };
                    text = result.driveSetRecord.length
                      ? `发生时间：${
                          result.driveSetRecord[0]?.realTime
                        }，事件类型：${map[result.driveSetRecord[0]?.type] ||
                          ""}`
                      : "无数据";
                  } else if (result.driveSpeedStatus) {
                    driveSpeedStatusRes.push(...result.driveSpeedStatus);
                    driveSpeedStatusRes.sort(
                      (a, b) =>
                        new Date(b.beginTime).valueOf() -
                        new Date(a.beginTime).valueOf()
                    );
                    text = driveSpeedStatusRes.length
                      ? `开始时间：${driveSpeedStatusRes[0]?.beginTime}，结束时间：${driveSpeedStatusRes[0]?.endTime}，速度状态：${driveSpeedStatusRes[0]?.statusStr}`
                      : "无数据";
                    dataInfo = driveSpeedStatusRes;
                    commandFlag = true;
                  }

                  callObject.GpsInteractionDataCallbackReal(
                    thisData,
                    text,
                    dataInfo,
                    commandFlag //因返回结果是分包返回的，所以传入指令标识动态修改interactiveArr数据
                  );
                  break;
                case 0x8201: // 部标指令-点名
                  // 点名返回的位置数据
                  oneGpsToLngLat(result.longitude, result.latitude).then(
                    res => {
                      getPlace(res[0], res[1]).then(res => {
                        callObject.GpsInteractionDataCallback(
                          thisData,
                          `当前位置:  ${res}`
                        );
                      });
                    }
                  );

                  break;
                //实时拍照
                case 34817:
                  callObject.GpsInteractionDataCallbackReal(
                    thisData,
                    "应答成功",
                    result.mediaIDs.join(",")
                  );
                  break;
                case 33028: // 查询终端参数 =》 所有终端参数
                  // console.log("查询终端参数", result);
                  let noteInfo = [];
                  let alarmInfo = [];
                  let netInfo = [];
                  let avInfo = []; //音视频
                  let avInoObj = []; //音视频参数回显页面
                  let heightInfo = []; //载重限高
                  let nightInfo = []; //夜间限速
                  let adasInfo = [];
                  let dsmInfo = [];
                  let tireInfo = [];
                  let blindInfo = [];
                  let fierceInfo = [];
                  let shieldingAlarmInfo = []; //gps屏蔽报警
                  let closeAlarmVoiceInfo = []; //gps关闭报警声音
                  //区分gps和出租车
                  if (result.parameters) {
                    //gps
                    const parameters = [...result.parameters];
                    if (parameters.length > 1) {
                      //屏蔽报警
                      parametersKey.shieldingAlarm.forEach(element => {
                        const parameterVal =
                          parameters.find(item => element.id === item.id) ||
                          false;
                        switch (element.id) {
                          case 80:
                            if (parameterVal) {
                              let lockValue = parseInt(parameterVal.value)
                                .toString(2)
                                .padStart(32, 0);
                              lockValue
                                .split("")
                                .reverse()
                                .map((item, i) => {
                                  if (i !== lockValue.length - 1) {
                                    shieldingAlarmInfo.push({
                                      name: alarmShieldGps[i],
                                      value: item === "1" ? "开启" : "关闭"
                                    });
                                  }
                                });
                            }
                            break;
                          default:
                            alarmInfo.push({
                              name: element.name,
                              value: parameterVal
                                ? parameterVal.value
                                : "暂无数据"
                            });
                            break;
                        }
                      });
                      //关闭报警声音
                      parametersKey.closeAlarmVoice.forEach(element => {
                        const parameterVal =
                          parameters.find(item => element.id === item.id) ||
                          false;
                        switch (element.id) {
                          case 81:
                            if (parameterVal) {
                              let lockValue = parseInt(parameterVal.value)
                                .toString(2)
                                .padStart(32, 0);
                              lockValue
                                .split("")
                                .reverse()
                                .map((item, i) => {
                                  if (i !== lockValue.length - 1) {
                                    closeAlarmVoiceInfo.push({
                                      name: alarmShieldGps[i],
                                      value: item === "1" ? "开启" : "关闭"
                                    });
                                  }
                                });
                            }

                            break;
                          default:
                            alarmInfo.push({
                              name: element.name,
                              value: parameterVal
                                ? parameterVal.value
                                : "暂无数据"
                            });
                            break;
                        }
                      });
                      parametersKey.alarm.forEach(element => {
                        const parameterVal = parameters.find(
                          item => element.id === item.id
                        );
                        const flag =
                          parameters !== null && parameterVal !== undefined;
                        let realVal = "";
                        switch (element.id) {
                          case 86:
                            realVal = flag
                              ? YHTools.FormatTimeBySeconds(parameterVal.value)
                              : " 00:00:00";
                            alarmInfo.push({
                              name: element.name,
                              value: realVal
                            });
                            break;
                          case 87:
                            realVal = flag
                              ? YHTools.FormatTimeBySeconds(parameterVal.value)
                              : " 00:00:00";
                            alarmInfo.push({
                              name: element.name,
                              value: realVal
                            });

                            break;
                          case 88:
                            realVal = flag
                              ? YHTools.FormatTimeBySeconds(parameterVal.value)
                              : " 00:00:00";
                            alarmInfo.push({
                              name: element.name,
                              value: realVal
                            });

                            break;
                          case 89:
                            realVal = flag
                              ? YHTools.FormatTimeBySeconds(parameterVal.value)
                              : " 00:00:00";
                            alarmInfo.push({
                              name: element.name,
                              value: realVal
                            });

                            break;
                          case 90:
                            realVal = flag
                              ? YHTools.FormatTimeBySeconds(parameterVal.value)
                              : " 00:00:00";
                            alarmInfo.push({
                              name: element.name,
                              value: realVal
                            });
                            break;
                          case 91:
                            realVal = flag
                              ? Number(parameterVal.value) / 10
                              : "暂无数据";
                            alarmInfo.push({
                              name: element.name,
                              value: realVal
                            });
                            break;
                          case 92:
                            realVal = flag
                              ? YHTools.FormatTimeBySeconds(parameterVal.value)
                              : " 00:00:00";
                            alarmInfo.push({
                              name: element.name,
                              value: realVal
                            });
                            break;
                          default:
                            alarmInfo.push({
                              name: element.name,
                              value: flag ? parameterVal.value : "暂无数据"
                            });
                            break;
                        }
                      });
                      parametersKey.net.forEach(element => {
                        let parameterVal = parameters.find(
                          item => element.id === item.id
                        );
                        // console.log('net',parameterVal)
                        let flag =
                          parameters !== null && parameterVal !== undefined;
                        let realVal = "";
                        let value = parameterVal ? parameterVal.value : "";
                        // console.log('2222',value)
                        switch (element.id) {
                          case 1:
                            realVal = flag
                              ? YHTools.FormatTimeBySeconds(value)
                              : " 00:00:00";
                            netInfo.push({
                              name: element.name,
                              value: realVal
                            });
                            break;

                          case 40:
                            realVal = flag
                              ? YHTools.FormatTimeBySeconds(parameterVal.value)
                              : " 00:00:00";
                            netInfo.push({
                              name: element.name,
                              value: realVal
                            });

                            break;
                          case 41:
                            realVal = flag
                              ? YHTools.FormatTimeBySeconds(parameterVal.value)
                              : " 00:00:00";
                            netInfo.push({
                              name: element.name,
                              value: realVal
                            });

                            break;
                          case 44:
                            netInfo.push({
                              name: element.name,
                              value: flag
                                ? `${parameterVal.value} 米`
                                : "暂无数据"
                            });

                            break;
                          default:
                            netInfo.push({
                              name: element.name,
                              value: flag ? parameterVal.value : "暂无数据"
                            });

                            break;
                        }
                      });
                      parametersKey.other.forEach(element => {
                        let parameterVal = parameters.find(
                          item => element.id === item.id
                        );
                        // console.log('other',parameterVal)
                        let flag =
                          parameters !== null && parameterVal !== undefined;
                        let realVal = "";
                        let value = parameterVal ? parameterVal.value : "";
                        // console.log(3333,value)
                        switch (element.id) {
                          case 2:
                            realVal = flag
                              ? YHTools.FormatTimeBySeconds(value)
                              : " 00:00:00";
                            noteInfo.push({
                              name: element.name,
                              value: realVal
                            });

                            break;
                          case 6:
                            realVal = flag
                              ? YHTools.FormatTimeBySeconds(value)
                              : " 00:00:00";
                            noteInfo.push({
                              name: element.name,
                              value: realVal
                            });

                            break;
                          case 32:
                            switch (value) {
                              case 0:
                                realVal = "定时汇报";
                                break;
                              case 1:
                                realVal = "定距汇报";
                                break;
                              case 2:
                                realVal = "定时和定距汇报";
                                break;
                              default:
                                realVal = "暂无数据";
                                break;
                            }
                            noteInfo.push({
                              name: element.name,
                              value: realVal
                            });

                            break;

                          case 33:
                            switch (value) {
                              case 0:
                                realVal = "根据ACC状态";
                                break;
                              case 1:
                                realVal =
                                  "根据登录状态和ACC状态,先判断登录状态,若登录再根据ACC状态";
                                break;
                              default:
                                realVal = "暂无数据";
                                break;
                            }
                            noteInfo.push({
                              name: element.name,
                              value: realVal
                            });
                            break;
                          case 34:
                            realVal = flag
                              ? YHTools.FormatTimeBySeconds(parameterVal.value)
                              : " 00:00:00";
                            noteInfo.push({
                              name: element.name,
                              value: realVal
                            });

                            break;
                          case 39:
                            realVal = flag
                              ? YHTools.FormatTimeBySeconds(parameterVal.value)
                              : " 00:00:00";
                            noteInfo.push({
                              name: element.name,
                              value: realVal
                            });

                            break;
                          case 128:
                            noteInfo.push({
                              name: element.name,
                              value: flag
                                ? `${Number(parameterVal.value) / 10} km`
                                : "暂无数据"
                            });

                            break;
                          case 132:
                            switch (value) {
                              case 1:
                                realVal = "蓝色";
                                break;
                              case 2:
                                realVal = "黄色";
                                break;
                              case 3:
                                realVal = "白色";
                                break;
                              case 4:
                                realVal = "黄色";
                                break;
                              default:
                                realVal = "其他";
                                break;
                            }
                            noteInfo.push({
                              name: element.name,
                              value: realVal
                            });
                            break;
                          default:
                            noteInfo.push({
                              name: element.name,
                              value: flag ? parameterVal.value : "暂无数据"
                            });

                            break;
                        }
                      });
                      // 谢 2022.1.10查询所有终端参数---夜间限速+音视频+载重限高
                      // 音视频
                      parametersKey.audioAndVideo.forEach(element => {
                        let aVVal = parameters.find(
                          item => element.id === item.id
                        );
                        let flag = parameters !== null && aVVal !== undefined;
                        let valueAv = aVVal ? aVVal.value : [];
                        switch (element.id) {
                          case 117:
                            avInoObj = valueAv; //音视频参数设置回显
                            if (valueAv.length > 0) {
                              let v0 = "";
                              let v1 = "";
                              let v5 = "";
                              let v6 = "";
                              let v11 = "";
                              switch (valueAv[0]) {
                                case 0:
                                  v0 = "CBR(固定码率)";
                                  break;
                                case 1:
                                  v0 = "VBR(可变码率)";
                                  break;
                                case 2:
                                  v0 = "ABR(平均码率)";
                                  break;
                                default:
                                  v0 = "自定义";
                                  break;
                              }
                              avInfo.push({
                                name: "实时流编码模式",
                                value: v0
                              });

                              switch (valueAv[1]) {
                                case 0:
                                  v1 = "QCIF";
                                  break;
                                case 1:
                                  v1 = "CIF";
                                  break;
                                case 2:
                                  v1 = "WCIF";
                                  break;
                                case 3:
                                  v1 = "D1";
                                  break;
                                case 4:
                                  v1 = "WD1";
                                  break;
                                case 5:
                                  v1 = "720P";
                                  break;
                                case 6:
                                  v1 = "1080P";
                                  break;
                                default:
                                  v1 = "自定义";
                                  break;
                              }
                              avInfo.push({
                                name: "实时流分辨率",
                                value: v1
                              });
                              avInfo.push({
                                name: "实时流关键帧间隔",
                                value: `${valueAv[2]}帧`
                              });
                              avInfo.push({
                                name: "实时流目标帧率",
                                value: `${valueAv[3]}帧/s`
                              });
                              avInfo.push({
                                name: "实时流目标码率",
                                value: `${valueAv[4]}kbps`
                              });
                              switch (valueAv[5]) {
                                case 0:
                                  v5 = "CBR(固定码率)";
                                  break;
                                case 1:
                                  v5 = "VBR(可变码率)";
                                  break;
                                case 2:
                                  v5 = "ABR(平均码率)";
                                  break;
                                default:
                                  v5 = "自定义";
                                  break;
                              }
                              avInfo.push({
                                name: "存储流编码模式",
                                value: v5
                              });
                              switch (valueAv[6]) {
                                case 0:
                                  v6 = "QCIF";
                                  break;
                                case 1:
                                  v6 = "CIF";
                                  break;
                                case 2:
                                  v6 = "WCIF";
                                  break;
                                case 3:
                                  v6 = "D1";
                                  break;
                                case 4:
                                  v6 = "WD1";
                                  break;
                                case 5:
                                  v6 = "720P";
                                  break;
                                case 6:
                                  v6 = "1080P";
                                  break;
                                default:
                                  v6 = "自定义";
                                  break;
                              }
                              avInfo.push({
                                name: "存储流分辨率",
                                value: v6
                              });
                              avInfo.push({
                                name: "存储流关键帧间隔",
                                value: `${valueAv[7]}帧`
                              });
                              avInfo.push({
                                name: "存储流目标帧率",
                                value: `${valueAv[8]}帧/s`
                              });
                              avInfo.push({
                                name: "存储流目标码率",
                                value: `${valueAv[9]}kbps`
                              });
                              // 二进制转换
                              let bfArr = Number(valueAv[10]);
                              let bit2 = bfArr
                                .toString(2)
                                .split("")
                                .reverse();
                              let l1 = bit2.length;
                              bit2.length = 16;
                              let bit16 = bit2.fill("0", l1, 16);
                              let nameArr = [];
                              let bitText = "";
                              const arrList = [
                                {
                                  s: 0,
                                  e: 0,
                                  name: "日期和时间"
                                },
                                {
                                  s: 1,
                                  e: 1,
                                  name: "车牌号码"
                                },
                                {
                                  s: 2,
                                  e: 2,
                                  name: "逻辑通道号"
                                },
                                {
                                  s: 3,
                                  e: 3,
                                  name: "经纬度"
                                },
                                {
                                  s: 4,
                                  e: 4,
                                  name: "行驶记录速度"
                                },
                                {
                                  s: 5,
                                  e: 5,
                                  name: "卫星定位速度"
                                },
                                {
                                  s: 6,
                                  e: 6,
                                  name: "连续驾驶时间"
                                },
                                {
                                  s: 7,
                                  e: 10,
                                  name: "保留"
                                },
                                {
                                  s: 11,
                                  e: 15,
                                  name: "自定义"
                                }
                              ];
                              bit16.forEach((item, index) => {
                                if (item == "1") {
                                  let current =
                                    arrList.find(
                                      temp =>
                                        temp.s >= Number(index) &&
                                        temp.e >= Number(index)
                                    ) || false;
                                  current && nameArr.push(current);
                                }
                              });
                              nameArr.map(item => {
                                bitText += item.name;
                              });
                              avInfo.push({
                                name: "OSD 字幕叠加设置",
                                value: bitText ? bitText : ""
                              });
                              switch (valueAv[11]) {
                                case 0:
                                  v11 = "不启用";
                                  break;
                                case 1:
                                  v11 = "启用";
                                  break;
                                default:
                                  v11 = "自定义";
                                  break;
                              }
                              avInfo.push({
                                name: "是否启用音频输出",
                                value: valueAv[11]
                              });
                            }
                            break;
                          default:
                            avInfo.push({
                              name: element.name,
                              value: flag ? aVVal.value : "暂无数据"
                            });

                            break;
                        }
                      });
                      //载重限高检测参数
                      parametersKey.limitHeight.forEach(element => {
                        let parameterVal = parameters.find(
                          item => element.id === item.id
                        );
                        let flag =
                          parameters !== null && parameterVal !== undefined;
                        let realVal = "";
                        let value = parameterVal ? parameterVal.value : [];
                        switch (element.id) {
                          //载重
                          case 62322:
                            if (value.length > 0) {
                              let v = "";
                              heightInfo.push({
                                name: "车辆限定总质量",
                                value: `${value[0]}*10Kg`
                              });
                              heightInfo.push({
                                name: "发动机最大扭矩",
                                value: `${value[1]}牛.米`
                              });
                              heightInfo.push({
                                name: "车速1 CAN报文ID",
                                value: value[2]
                              });
                              heightInfo.push({
                                name: "车速2 CAN报文ID",
                                value: value[3]
                              });
                              switch (value[4]) {
                                case 1:
                                  v = "安装朝向车辆驾驶室";
                                  break;
                                case 2:
                                  v = "安装朝向车辆尾部";
                                  break;
                                default:
                                  v = "-";
                                  break;
                              }
                              heightInfo.push({
                                name: "监测设备安装方向",
                                value: v
                              });
                              heightInfo.push({
                                name: "载重计算周期",
                                value: `${value[5]}*10毫秒`
                              });
                              heightInfo.push({
                                name: "设置计算滤波系数",
                                value: value[6]
                              });
                              heightInfo.push({
                                name: "加速度初始值",
                                value: value[7]
                              });
                            }
                            break;
                          //限高
                          case 62323:
                            if (value.length > 0) {
                              heightInfo.push({
                                name: "车身高度",
                                value: `${value[0]}毫米`
                              });
                              heightInfo.push({
                                name: "车身宽度",
                                value: `${value[1]}毫米`
                              });
                            }
                            break;
                          default:
                            heightInfo.push({
                              name: element.name,
                              value: flag ? parameterVal.value : "暂无数据"
                            });
                            break;
                        }
                      });
                      //夜间限速设置
                      parametersKey.lightSet.forEach(element => {
                        let parameterVal = parameters.find(
                          item => element.id === item.id
                        );
                        let flag =
                          parameters !== null && parameterVal !== undefined;
                        let realVal = "";
                        let value = parameterVal ? parameterVal.value : [];
                        switch (element.id) {
                          case 61441:
                            if (value.length > 0) {
                              let v = "";
                              switch (value[0]) {
                                case 0:
                                  v = "关闭";
                                  break;
                                case 1:
                                  v = "打开";
                                  break;
                                default:
                                  v = "-";
                                  break;
                              }
                              nightInfo.push({
                                name: "报警开关",
                                value: v
                              });
                              nightInfo.push({
                                name: "夜间相对白天限速值百分比",
                                value: value[1]
                              });
                              nightInfo.push({
                                name: "夜间开始时间",
                                value: value[2]
                              });
                              nightInfo.push({
                                name: "夜间结束时间",
                                value: value[3]
                              });
                            }
                            break;
                          default:
                            nightInfo.push({
                              name: element.name,
                              value: flag ? parameterVal.value : "暂无数据"
                            });

                            break;
                        }
                      });
                      //高级参数-adas
                      parametersKey.adasSet.forEach(element => {
                        let parameterVal = parameters.find(
                          item => element.id === item.id
                        );
                        let flag =
                          parameters !== null && parameterVal !== undefined;
                        let realVal = "";
                        let value = parameterVal ? parameterVal.value : [];
                        let tempArr = [];
                        const takeList = [
                          { dictValue: "不开启", dictCode: 0 },
                          { dictValue: "定时拍照", dictCode: 1 },
                          { dictValue: "定距拍照", dictCode: 2 },
                          { dictValue: "保留", dictCode: 3 }
                        ];
                        const ratioList = [
                          { dictValue: "352*288", dictCode: 1 },
                          { dictValue: "704*288", dictCode: 2 },
                          { dictValue: "704*576", dictCode: 3 },
                          { dictValue: "640*480", dictCode: 4 },
                          { dictValue: "1280*720", dictCode: 5 },
                          { dictValue: "1920*1080", dictCode: 6 }
                        ];
                        const resolutionRatioList = [
                          { dictValue: "CIF", dictCode: 1 },
                          { dictValue: "HD1", dictCode: 2 },
                          { dictValue: "D1", dictCode: 3 },
                          { dictValue: "WD1", dictCode: 4 },
                          { dictValue: "VGA", dictCode: 5 },
                          { dictValue: "720P", dictCode: 6 },
                          { dictValue: "1080P", dictCode: 7 }
                        ];
                        switch (element.id) {
                          case 62308:
                            //报警使能:arr[9],事件使能arr[10],预留字段arr[11]
                            value.forEach((item, index) => {
                              if (index === 9) {
                                let bfArr = Number(item.value);
                                let bit2 = bfArr
                                  .toString(2)
                                  .split("")
                                  .reverse();
                                let l1 = bit2.length;
                                bit2.length = 32;
                                let bit32 = bit2.fill("0", l1, 32);
                                tempArr = [
                                  {
                                    name: "障碍物一级报警",
                                    value: bit32[0] === "1" ? "打开" : "关闭"
                                  },
                                  {
                                    name: "障碍物二级报警",
                                    value: bit32[1] === "1" ? "打开" : "关闭"
                                  },
                                  {
                                    name: "频繁变道一级报警",
                                    value: bit32[2] === "1" ? "打开" : "关闭"
                                  },
                                  {
                                    name: "频繁变道二级报警",
                                    value: bit32[3] === "1" ? "打开" : "关闭"
                                  },
                                  {
                                    name: "车道偏离一级报警",
                                    value: bit32[4] === "1" ? "打开" : "关闭"
                                  },
                                  {
                                    name: "车道偏离二级报警",
                                    value: bit32[5] === "1" ? "打开" : "关闭"
                                  },
                                  {
                                    name: "前向碰撞一级报警",
                                    value: bit32[6] === "1" ? "打开" : "关闭"
                                  },
                                  {
                                    name: "前向碰撞二级报警",
                                    value: bit32[7] === "1" ? "打开" : "关闭"
                                  },
                                  {
                                    name: "行人碰撞一级报警",
                                    value: bit32[8] === "1" ? "打开" : "关闭"
                                  },
                                  {
                                    name: "行人碰撞二级报警",
                                    value: bit32[9] === "1" ? "打开" : "关闭"
                                  },
                                  {
                                    name: "车距过近一级报警",
                                    value: bit32[10] === "1" ? "打开" : "关闭"
                                  },
                                  {
                                    name: "车距过近二级报警",
                                    value: bit32[11] === "1" ? "打开" : "关闭"
                                  },
                                  {
                                    name: "道路标识超速报警",
                                    value: bit32[16] === "1" ? "打开" : "关闭"
                                  }
                                ];
                                adasInfo = adasInfo.concat(tempArr);
                                //兼容2019新增
                                value.length > 46 &&
                                  adasInfo.push({
                                    name: "碰撞侧翻报警",
                                    value: bit32[17] === "1" ? "打开" : "关闭"
                                  });
                              } else if (index === 2) {
                                adasInfo.push({
                                  name: item.name,
                                  value: takeList.find(
                                    i => i.dictCode === item.value
                                  ).dictValue
                                });
                              } else if (index === 7) {
                                adasInfo.push({
                                  name: item.name,
                                  value: ratioList.find(
                                    i => i.dictCode === item.value
                                  ).dictValue
                                });
                              } else if (index === 8) {
                                adasInfo.push({
                                  name: item.name,
                                  value: resolutionRatioList.find(
                                    i => i.dictCode === item.value
                                  ).dictValue
                                });
                              } else if (
                                index !== 10 &&
                                index !== 11 &&
                                index !== value.length - 1
                              ) {
                                adasInfo.push(item);
                              }
                            });
                            break;
                        }
                      });
                      //高级参数-dsm
                      parametersKey.dsmSet.forEach(element => {
                        let parameterVal = parameters.find(
                          item => element.id === item.id
                        );
                        let flag =
                          parameters !== null && parameterVal !== undefined;
                        let realVal = "";
                        let value = parameterVal ? parameterVal.value : [];
                        let tempArr = [];
                        const takeList = [
                          { dictValue: "不开启", dictCode: 0 },
                          { dictValue: "定时拍照", dictCode: 1 },
                          { dictValue: "定距拍照", dictCode: 2 },
                          { dictValue: "保留", dictCode: 3 }
                        ];
                        const ratioList = [
                          { dictValue: "352*288", dictCode: 1 },
                          { dictValue: "704*288", dictCode: 2 },
                          { dictValue: "704*576", dictCode: 3 },
                          { dictValue: "640*480", dictCode: 4 },
                          { dictValue: "1280*720", dictCode: 5 },
                          { dictValue: "1920*1080", dictCode: 6 }
                        ];
                        const resolutionRatioList = [
                          { dictValue: "CIF", dictCode: 1 },
                          { dictValue: "HD1", dictCode: 2 },
                          { dictValue: "D1", dictCode: 3 },
                          { dictValue: "WD1", dictCode: 4 },
                          { dictValue: "VGA", dictCode: 5 },
                          { dictValue: "720P", dictCode: 6 },
                          { dictValue: "1080P", dictCode: 7 }
                        ];
                        const driverList = [
                          { dictValue: "不开启", dictCode: 0 },
                          { dictValue: "定时触发", dictCode: 1 },
                          { dictValue: "定距触发", dictCode: 2 },
                          { dictValue: "插卡开始行驶触发", dictCode: 3 },
                          { dictValue: "保留", dictCode: 4 }
                        ];
                        switch (element.id) {
                          case 62309:
                            //报警使能:arr[9],事件使能arr[10],预留字段arr[13]
                            value.forEach((item, index) => {
                              if (index === 9) {
                                let bfArr = Number(item.value);
                                let bit2 = bfArr
                                  .toString(2)
                                  .split("")
                                  .reverse();
                                let l1 = bit2.length;
                                bit2.length = 32;
                                let bit32 = bit2.fill("0", l1, 32);
                                tempArr = [
                                  {
                                    name: "疲劳驾驶一级报警",
                                    value: bit32[0] === "1" ? "打开" : "关闭"
                                  },
                                  {
                                    name: "疲劳驾驶二级报警",
                                    value: bit32[1] === "1" ? "打开" : "关闭"
                                  },
                                  {
                                    name: "接打电话一级报警",
                                    value: bit32[2] === "1" ? "打开" : "关闭"
                                  },
                                  {
                                    name: "接打电话二级报警",
                                    value: bit32[3] === "1" ? "打开" : "关闭"
                                  },
                                  {
                                    name: "抽烟一级报警",
                                    value: bit32[4] === "1" ? "打开" : "关闭"
                                  },
                                  {
                                    name: "抽烟二级报警",
                                    value: bit32[5] === "1" ? "打开" : "关闭"
                                  },
                                  {
                                    name: "长时间不目视前方一级报警",
                                    value: bit32[6] === "1" ? "打开" : "关闭"
                                  },
                                  {
                                    name: "长时间不目视前方二级报警",
                                    value: bit32[7] === "1" ? "打开" : "关闭"
                                  },
                                  {
                                    name: "未检测到驾驶员二级报警",
                                    value: bit32[9] === "1" ? "打开" : "关闭"
                                  }
                                ];
                                dsmInfo = dsmInfo.concat(tempArr);
                                //兼容2019新增
                                if (value.length > 37) {
                                  dsmInfo = dsmInfo.concat([
                                    {
                                      name: "夜间禁行报警",
                                      value: bit32[10] === "1" ? "打开" : "关闭"
                                    },
                                    {
                                      name: "超速驾驶报警",
                                      value: bit32[11] === "1" ? "打开" : "关闭"
                                    }
                                  ]);
                                }
                              } else if (index === 2) {
                                dsmInfo.push({
                                  name: item.name,
                                  value: takeList.find(
                                    i => i.dictCode === item.value
                                  ).dictValue
                                });
                              } else if (index === 7) {
                                dsmInfo.push({
                                  name: item.name,
                                  value: ratioList.find(
                                    i => i.dictCode === item.value
                                  ).dictValue
                                });
                              } else if (index === 8) {
                                dsmInfo.push({
                                  name: item.name,
                                  value: resolutionRatioList.find(
                                    i => i.dictCode === item.value
                                  ).dictValue
                                });
                              } else if (index === 34) {
                                dsmInfo.push({
                                  name: item.name,
                                  value: driverList.find(
                                    i => i.dictCode === item.value
                                  ).dictValue
                                });
                              } else if (
                                index !== 10 &&
                                index !== 13 &&
                                index !== value.length - 1
                              ) {
                                dsmInfo.push(item);
                              }
                            });
                            break;
                        }
                      });
                      //高级参数-轮胎检测
                      parametersKey.tireSet.forEach(element => {
                        let parameterVal = parameters.find(
                          item => element.id === item.id
                        );
                        let flag =
                          parameters !== null && parameterVal !== undefined;
                        let realVal = "";
                        let value = parameterVal ? parameterVal.value : [];
                        const unitList = [
                          { dictValue: "kg/cm2", dictCode: 0 },
                          { dictValue: "bar", dictCode: 1 },
                          { dictValue: "Kpa", dictCode: 2 },
                          { dictValue: "PSI", dictCode: 3 }
                        ];
                        switch (element.id) {
                          case 62310:
                            value.forEach((item, index) => {
                              if (index === 1) {
                                tireInfo.push({
                                  name: item.name,
                                  value: unitList.find(
                                    i => i.dictCode === item.value
                                  ).dictValue
                                });
                              } else if (index !== value.length - 1) {
                                tireInfo.push(item);
                              }
                            });
                            break;
                        }
                      });
                      //高级参数-盲区监测
                      parametersKey.blindSet.forEach(element => {
                        let parameterVal = parameters.find(
                          item => element.id === item.id
                        );
                        let flag =
                          parameters !== null && parameterVal !== undefined;
                        let realVal = "";
                        let value = parameterVal ? parameterVal.value : [];
                        switch (element.id) {
                          case 62311:
                            blindInfo = value || [];
                            break;
                        }
                      });
                      //高级参数-激烈驾驶
                      parametersKey.fierceSet.forEach(element => {
                        let parameterVal = parameters.find(
                          item => element.id === item.id
                        );
                        let flag =
                          parameters !== null && parameterVal !== undefined;
                        let realVal = "";
                        let value = parameterVal ? parameterVal.value : [];
                        let tempArr = [];
                        switch (element.id) {
                          case 62320:
                            value.forEach((item, index) => {
                              if (index === 0) {
                                let bfArr = Number(item.value);
                                let bit2 = bfArr
                                  .toString(2)
                                  .split("")
                                  .reverse();
                                let l1 = bit2.length;
                                bit2.length = 32;
                                let bit32 = bit2.fill("0", l1, 32);
                                tempArr = [
                                  {
                                    name: "急加速报警",
                                    value: bit32[0] === "1" ? "打开" : "关闭"
                                  },
                                  {
                                    name: "急减速报警",
                                    value: bit32[1] === "1" ? "打开" : "关闭"
                                  },
                                  {
                                    name: "急转弯报警",
                                    value: bit32[2] === "1" ? "打开" : "关闭"
                                  },
                                  {
                                    name: "怠速报警",
                                    value: bit32[3] === "1" ? "打开" : "关闭"
                                  },
                                  {
                                    name: "异常熄火报警",
                                    value: bit32[4] === "1" ? "打开" : "关闭"
                                  },
                                  {
                                    name: "空挡滑行报警",
                                    value: bit32[5] === "1" ? "打开" : "关闭"
                                  },
                                  {
                                    name: "发动机超转报警",
                                    value: bit32[6] === "1" ? "打开" : "关闭"
                                  }
                                ];
                                fierceInfo = fierceInfo.concat(tempArr);
                              } else if (index !== value.length - 1) {
                                fierceInfo.push(item);
                              }
                            });
                            break;
                        }
                      });
                      let note = {
                        alarm: alarmInfo,
                        net: netInfo,
                        other: noteInfo,
                        audioAndVideo: avInfo,
                        limitHeight: heightInfo,
                        night: nightInfo,
                        adas: adasInfo,
                        dsm: dsmInfo,
                        tire: tireInfo,
                        blind: blindInfo,
                        fierce: fierceInfo,
                        shieldingAlarm: shieldingAlarmInfo,
                        closeAlarmVoice: closeAlarmVoiceInfo
                      };
                      callObject.GpsInteractionDataCallback(thisData, note);
                      //载重限高
                      callObject.RefreshDataBack(
                        RefreshTypeEnum.GB_QueryAlarmSetingLimit,
                        heightInfo
                      );
                      //音视频
                      callObject.RefreshDataBack(
                        RefreshTypeEnum.GB_QueryAlarmSetingAV,
                        avInoObj
                      );
                      //查询终端参数+高级参数
                      callObject.RefreshDataBack(
                        RefreshTypeEnum.GB_QueryAlarmSeting,
                        parameters
                      );
                    } else {
                      // 查询终端参数报警屏蔽设置
                      callObject.RefreshDataBack(
                        RefreshTypeEnum.GB_QueryAlarmSeting,
                        parameters[0]
                      );
                    }
                  } else {
                    let parameters = [...result];
                    if (parameters.length >= 1) {
                      parametersKey2.forEach(element => {
                        let parameterVal = parameters.find(
                          item => element.id === item.id
                        );
                        let flag = parameters !== null;
                        let realVal = "";
                        let value = parameterVal
                          ? typeof parameterVal.value === "string"
                            ? parameterVal.value.replace("\u0000", "")
                            : parameterVal.value
                          : "";
                        let taxiAvValue = parameterVal
                          ? parameterVal.value
                          : [];
                        switch (element.id) {
                          //出租车屏蔽报警
                          case 80:
                              let lockValue = parseInt(parameterVal.value)
                                .toString(2)
                                .padStart(29, 0);
                              lockValue
                                .split("")
                                .reverse()
                                .map((item, i) => {
                                  if (i !== lockValue.length - 1) {
                                    noteInfo.push({
                                      name: alarmShield[i],
                                      value: item === "1" ? "开启" : "关闭"
                                    });
                                  }
                                });
                            break;
                            case 81:
                              if (parameterVal) {
                                let lockValue = parseInt(parameterVal.value)
                                  .toString(2)
                                  .padStart(29, 0);
                                lockValue
                                  .split("")
                                  .reverse()
                                  .map((item, i) => {
                                    if (i !== lockValue.length - 1) {
                                      closeAlarmVoiceInfo.push({
                                        name: alarmShield[i],
                                        value: item === "1" ? "开启" : "关闭"
                                      });
                                    }
                                  });
                              }
  
                              break;
                          case 0x0020:
                            switch (value) {
                              case 0:
                                realVal = "定时汇报";
                                break;
                              case 1:
                                realVal = "定距汇报";
                                break;
                              case 2:
                                realVal = "定时和定距汇报";
                                break;
                              default:
                                realVal = "暂无数据";
                                break;
                            }
                            noteInfo.push({
                              name: element.name,
                              value: realVal
                            });

                            break;

                          case 0x0021:
                            switch (value) {
                              case 0:
                                realVal = "根据ACC状态";
                                break;
                              case 1:
                                realVal = "根据空重车状态";
                                break;
                              case 2:
                                realVal =
                                  "根据登录状态和ACC状态，先判断登录状态,若登录再根据ACC状态";
                                break;
                              case 3:
                                realVal =
                                  "根据登录状态和空重车状态，先判断登录状态，若登录再根据空重车状态";
                                break;
                              default:
                                realVal = "暂无数据";
                                break;
                            }
                            noteInfo.push({
                              name: element.name,
                              value: realVal
                            });
                            break;
                          case 0x0022:
                            realVal = parameterVal
                              ? YHTools.FormatTimeBySeconds(value)
                              : " 00:00:00";
                            noteInfo.push({
                              name: element.name,
                              value: realVal
                            });

                            break;
                          case 0x0024:
                            realVal = parameterVal
                              ? YHTools.FormatTimeBySeconds(value)
                              : " 00:00:00";
                            noteInfo.push({
                              name: element.name,
                              value: realVal
                            });

                            break;
                          case 0x0023:
                            realVal = parameterVal
                              ? YHTools.FormatTimeBySeconds(value)
                              : " 00:00:00";
                            noteInfo.push({
                              name: element.name,
                              value: realVal
                            });

                            break;
                          case 0x0025:
                            realVal = parameterVal
                              ? YHTools.FormatTimeBySeconds(value)
                              : " 00:00:00";
                            noteInfo.push({
                              name: element.name,
                              value: realVal
                            });

                            break;
                          case 0x0026:
                            realVal = parameterVal
                              ? YHTools.FormatTimeBySeconds(value)
                              : " 00:00:00";
                            noteInfo.push({
                              name: element.name,
                              value: realVal
                            });
                            break;
                          case 0x0027:
                            realVal = parameterVal
                              ? YHTools.FormatTimeBySeconds(value)
                              : " 00:00:00";
                            noteInfo.push({
                              name: element.name,
                              value: realVal
                            });
                            break;
                          case 0x0028:
                            realVal = parameterVal
                              ? YHTools.FormatTimeBySeconds(value)
                              : " 00:00:00";
                            noteInfo.push({
                              name: element.name,
                              value: realVal
                            });
                            break;
                          case 0x0056:
                            realVal = flag
                              ? YHTools.FormatTimeBySeconds(value)
                              : " 00:00:00";
                            noteInfo.push({
                              name: element.name,
                              value: realVal
                            });
                            break;
                          case 0x0057:
                            realVal = flag
                              ? YHTools.FormatTimeBySeconds(value)
                              : " 00:00:00";
                            noteInfo.push({
                              name: element.name,
                              value: realVal
                            });
                            break;
                          case 0x005a:
                            realVal = flag
                              ? YHTools.FormatTimeBySeconds(value)
                              : " 00:00:00";
                            noteInfo.push({
                              name: element.name,
                              value: realVal
                            });
                            break;
                          case 0x0058:
                            realVal = flag
                              ? YHTools.FormatTimeBySeconds(value)
                              : " 00:00:00";
                            noteInfo.push({
                              name: element.name,
                              value: realVal
                            });
                            break;
                          case 0x0059:
                            realVal = flag
                              ? YHTools.FormatTimeBySeconds(value)
                              : " 00:00:00";
                            noteInfo.push({
                              name: element.name,
                              value: realVal
                            });
                            break;
                          case 1:
                            realVal = flag
                              ? YHTools.FormatTimeBySeconds(value)
                              : " 00:00:00";
                            noteInfo.push({
                              name: element.name,
                              value: realVal
                            });
                            break;
                          //出租车音视频
                          case 0x0075:
                            avInoObj = taxiAvValue; //音视频参数设置回显
                            if (taxiAvValue.length > 0) {
                              let v0 = "";
                              let v1 = "";
                              let v5 = "";
                              let v6 = "";
                              let v11 = "";
                              switch (taxiAvValue[0]) {
                                case 0:
                                  v0 = "CBR(固定码率)";
                                  break;
                                case 1:
                                  v0 = "VBR(可变码率)";
                                  break;
                                case 2:
                                  v0 = "ABR(平均码率)";
                                  break;
                                default:
                                  v0 = "自定义";
                                  break;
                              }
                              avInfo.push({
                                name: "实时流编码模式",
                                value: v0
                              });

                              switch (taxiAvValue[1]) {
                                case 0:
                                  v1 = "QCIF";
                                  break;
                                case 1:
                                  v1 = "CIF";
                                  break;
                                case 2:
                                  v1 = "WCIF";
                                  break;
                                case 3:
                                  v1 = "D1";
                                  break;
                                case 4:
                                  v1 = "WD1";
                                  break;
                                case 5:
                                  v1 = "720P";
                                  break;
                                case 6:
                                  v1 = "1080P";
                                  break;
                                default:
                                  v1 = "自定义";
                                  break;
                              }
                              avInfo.push({
                                name: "实时流分辨率",
                                value: v1
                              });
                              avInfo.push({
                                name: "实时流关键帧间隔",
                                value: `${taxiAvValue[2]}帧`
                              });
                              avInfo.push({
                                name: "实时流目标帧率",
                                value: `${taxiAvValue[3]}帧/s`
                              });
                              avInfo.push({
                                name: "实时流目标码率",
                                value: `${taxiAvValue[4]}kbps`
                              });
                              switch (taxiAvValue[5]) {
                                case 0:
                                  v5 = "CBR(固定码率)";
                                  break;
                                case 1:
                                  v5 = "VBR(可变码率)";
                                  break;
                                case 2:
                                  v5 = "ABR(平均码率)";
                                  break;
                                default:
                                  v5 = "自定义";
                                  break;
                              }
                              avInfo.push({
                                name: "存储流编码模式",
                                value: v5
                              });
                              switch (taxiAvValue[6]) {
                                case 0:
                                  v6 = "QCIF";
                                  break;
                                case 1:
                                  v6 = "CIF";
                                  break;
                                case 2:
                                  v6 = "WCIF";
                                  break;
                                case 3:
                                  v6 = "D1";
                                  break;
                                case 4:
                                  v6 = "WD1";
                                  break;
                                case 5:
                                  v6 = "720P";
                                  break;
                                case 6:
                                  v6 = "1080P";
                                  break;
                                default:
                                  v6 = "自定义";
                                  break;
                              }
                              avInfo.push({
                                name: "存储流分辨率",
                                value: v6
                              });
                              avInfo.push({
                                name: "存储流关键帧间隔",
                                value: `${taxiAvValue[7]}帧`
                              });
                              avInfo.push({
                                name: "存储流目标帧率",
                                value: `${taxiAvValue[8]}帧/s`
                              });
                              avInfo.push({
                                name: "存储流目标码率",
                                value: `${taxiAvValue[9]}kbps`
                              });
                              // 二进制转换
                              let bfArr = Number(taxiAvValue[10]);
                              let bit2 = bfArr
                                .toString(2)
                                .split("")
                                .reverse();
                              let l1 = bit2.length;
                              bit2.length = 16;
                              let bit16 = bit2.fill("0", l1, 16);
                              let nameArr = [];
                              let bitText = "";
                              const arrList = [
                                {
                                  s: 0,
                                  e: 0,
                                  name: "日期和时间"
                                },
                                {
                                  s: 1,
                                  e: 1,
                                  name: "车牌号码"
                                },
                                {
                                  s: 2,
                                  e: 2,
                                  name: "逻辑通道号"
                                },
                                {
                                  s: 3,
                                  e: 3,
                                  name: "经纬度"
                                },
                                {
                                  s: 4,
                                  e: 4,
                                  name: "行驶记录速度"
                                },
                                {
                                  s: 5,
                                  e: 5,
                                  name: "卫星定位速度"
                                },
                                {
                                  s: 6,
                                  e: 6,
                                  name: "连续驾驶时间"
                                },
                                {
                                  s: 7,
                                  e: 10,
                                  name: "保留"
                                },
                                {
                                  s: 11,
                                  e: 15,
                                  name: "自定义"
                                }
                              ];
                              bit16.forEach((item, index) => {
                                if (item == "1") {
                                  let current =
                                    arrList.find(
                                      temp =>
                                        temp.s >= Number(index) &&
                                        temp.e >= Number(index)
                                    ) || false;
                                  current && nameArr.push(current);
                                }
                              });
                              nameArr.map(item => {
                                bitText += item.name;
                              });
                              avInfo.push({
                                name: "OSD 字幕叠加设置",
                                value: bitText ? bitText : ""
                              });
                              switch (taxiAvValue[11]) {
                                case 0:
                                  v11 = "不启用";
                                  break;
                                case 1:
                                  v11 = "启用";
                                  break;
                                default:
                                  v11 = "自定义";
                                  break;
                              }
                              avInfo.push({
                                name: "是否启用音频输出",
                                value: taxiAvValue[11]
                              });
                            }
                            break;
                          default:
                            noteInfo.push({
                              name: element.name,
                              value: parameterVal
                                ? parameterVal.value
                                : "暂无数据"
                            });
                            break;
                        }
                      });
                      callObject.GpsInteractionDataCallback(thisData, noteInfo);
                      //查询终端参数+高级参数
                      callObject.RefreshDataBack(
                        RefreshTypeEnum.GB_QueryAlarmSeting,
                        parameters
                      );
                      //音视频
                      callObject.RefreshDataBack(
                        RefreshTypeEnum.GB_QueryAlarmSetingAV,
                        avInoObj
                      );
                    } else {
                      // 查询终端参数报警屏蔽设置
                      callObject.RefreshDataBack(
                        RefreshTypeEnum.GB_QueryAlarmSeting,
                        parameters[0]
                      );
                    }
                  }

                  break;
                case 0x8107: // 查询终端属性 =》 所有终端属性  查询终端版本信息
                  /**单个查询 - 终端升级 */
                  callObject.RefreshDataBack(
                    RefreshTypeEnum.GB_QueryAttributes,
                    {
                      result: result,
                      device: thisData.terminalPhoneNo,
                      vehicleNo: thisData.vehicleNo
                    }
                  );
                  /**批量 下发指令查询1-100条 */
                  callObject.RefreshDataBack(
                    RefreshTypeEnum.GB_QueryTerminalProperties,
                    {
                      result: result,
                      device: thisData.terminalPhoneNo,
                      vehicleNo: thisData.vehicleNo
                    }
                  );
                  let info = [
                    {
                      name: "终端型号",
                      value: result.terminalModel
                    },
                    {
                      name: "终端ID",
                      value: result.terminalID
                    },
                    {
                      name: "终端SIM_ICCID",
                      value: result.SIM_ICCID
                    },
                    {
                      name: "硬件版本号",
                      value: result.hardwareVersion
                    },
                    {
                      name: "固件版本号",
                      value: result.softwareVersion
                    }
                  ];
                  callObject.GpsInteractionDataCallback(thisData, info);

                  break;

                case 0x9101: // 实时音视频传输请求 =》 查看实时视频请求
                  const paramData = {
                    channel: null,
                    device: thisData.terminalPhoneNo,
                    plate: "veh"
                  };
                  switch (resultCode) {
                    case 0:
                      callObject.RefreshDataBack(
                        RefreshTypeEnum.GPS_RealVideo,
                        {
                          flag: true,
                          data: paramData
                        }
                      );
                      break;
                    case 1:
                      callObject.RefreshDataBack(
                        RefreshTypeEnum.GPS_RealVideo,
                        {
                          flag: false,
                          data: paramData
                        }
                      );

                      break;
                    case 2:
                      callObject.RefreshDataBack(
                        RefreshTypeEnum.GPS_RealVideo,
                        {
                          flag: false,
                          data: paramData
                        }
                      );

                      break;
                    case 3:
                      callObject.RefreshDataBack(
                        RefreshTypeEnum.GPS_RealVideo,
                        {
                          flag: false,
                          data: paramData
                        }
                      );

                      break;

                    case 4:
                      callObject.RefreshDataBack(
                        RefreshTypeEnum.GPS_RealVideo,
                        {
                          flag: false,
                          data: paramData
                        }
                      );

                      break;
                    case 5:
                      callObject.RefreshDataBack(
                        RefreshTypeEnum.GPS_RealVideo,
                        {
                          flag: false,
                          data: paramData
                        }
                      );

                      break;
                  }

                  break;
                case 0x9205:
                  // console.log('result',result)
                  //区分gps 和出租车
                  if (terminalType == 1) {
                    callObject.RefreshDataBack(
                      RefreshTypeEnum.GPS_QueryTerminalResList,
                      result
                    );
                  } else {
                    //gps
                    callObject.RefreshDataBack(
                      RefreshTypeEnum.GPS_QueryTerminalResList,
                      result.res
                    );
                  }
                  break;

                case 0x9201: // 历史音视频传输请求 =》 历史视频请求 正常情况返回资源列表 非正常情况通用应答
                  let resultVideo = null;
                  //区分gps和出租车
                  if (terminalType == 1) {
                    if (result.length) {
                      resultVideo = result.length;
                    } else if (result.resultCode === 0) {
                      resultVideo = 1;
                    }
                  } else {
                    if (result.res && result.res.length) {
                      resultVideo = result.res.length;
                    } else if (result.resultCode === 0) {
                      resultVideo = 1;
                    }
                  }
                  if (resultVideo > 0) {
                    callObject.RefreshDataBack(
                      RefreshTypeEnum.GPS_HistoryVideo,
                      {
                        flag: true,
                        data: "车辆"
                      }
                    );
                  } else {
                    callObject.RefreshDataBack(
                      RefreshTypeEnum.GPS_HistoryVideo,
                      {
                        flag: false,
                        data: "终端"
                      }
                    );
                  }

                  break;

                default:
                  break;
              }
              break;
            case "0xE8": // 车辆上下线数据
              callObject.RefreshDataBack(RefreshTypeEnum.GPS_Logout, thisData);
              break;

            default:
              break;
          }
          // callObject.thisReceive.orderVehcileList.delete(thisData.terminalPhoneNo)
        }
      } else {
        console.log("没登陆,重连");
      }
    } catch (e) {
      // TODO handle the exception
      console.error("yh_sdp  GpsAddDataPacket  error: " + e.message);
    }
  }

  /**
   *  连接websocket
   */
  ConnectSocketServer(flag) {
    try {
      this.thisReceive.SocketConnect(true, false);
      if (this.thisReceive) this.thisReceive.bDownLoadLast = true;
      if (flag) {
        this.thisReceive.websocketOnError = 0;
      }
    } catch (e) {
      console.error("yh_sdp  ConnectSocketServer  error: " + e.message);
    }
  }

  /**
   *  关闭socket连接
   */
  CloseWebsocketLink() {
    if (this.thisReceive != null) {
      this.thisReceive.SocketConnect(false, false);

      this.thisReceive = null;
    }
  }

  /**
   * 关闭网络连接
   */
  CloseSocketLink() {
    this.bIsPassLogin = false;
    this.CloseWebsocketLink();
  }

  /// ////////////////////////////////////////////////////////////////////设备参数设置//////////////////////////////////////////////////////////////////////////////////

  /**
   * 通过websocket发送消息----发送指令(不需要显示在指令信息中)
   * @param {*} params 参数
   * @param {*} type   '0xE7'|'0xEC'
   */
  SendData(params, type) {
    if (this.thisReceive != null) {
      this.thisReceive.SendData(params, type);
    }
  }

  /**
   * 通过websocket发送消息----发送指令
   * @param {string} params 参数
   * @param {string} sCmdTxt 命令
   * @param {string} sNote 内容
   */
  SendSocketData(params, sCmdTxt, sNote) {
    //debugger
    if (this.thisReceive != null) {
      this.thisReceive.SendCmdToCenter(params, sCmdTxt, sNote);
    }
  }

  /**
   * 部标设备指令-点名
   *@param {Array} terminalNos 需要操作的车辆设备号数组
   */
  GBCalling(terminalNos, terminalType) {
    try {
      const objTemp = {
        command: 0x8201, // msgId,num类型
        params: [], // 发送内容
        terminalType: terminalType, // 发送内容
        terminalPhoneNos: terminalNos[0], // 需下发的手机号
        terminalDetail: terminalNos[1] // 需回显的信息
      };
      this.SendSocketData(JSON.stringify(objTemp), "点名", "");
    } catch (error) {
      console.error("yh_sdp  Calling  error: " + error.message);
    }
  }

  /**
   * 部标设备指令-文本信息下发(批量处理报警--相同车辆只发送一次指令)
   * @param {Array} terminalNos 需要操作的车辆设备号数组
   * @param {string} sDispatchType 调度类型  紧急,保留,终端显示器显示,终端TTS播读,广告屏显示,IC卡关联操作,显示+播报  中的一个
   * @param {string} sText 调度内容
   */

  GBSendMessage(terminalNos, sDispatchType, sText, terminalType) {
    try {
      const objTemp = {
        command: 0x8300, // msgId,num类型
        params: [sDispatchType, sText], // 发送内容
        terminalType: terminalType, // 发送内容
        terminalPhoneNos: terminalNos[0], // 需下发的手机号
        terminalDetail: terminalNos[1] // 需回显的信息
      };
      this.SendSocketData(
        JSON.stringify(objTemp),
        "文本信息下发",
        `${sDispatchType}-${sText}`
      );
    } catch (error) {
      console.error("yh_sdp  GB_SendMessage  error: " + error.message);
    }
  }

  /**
   * 部标设备指令-查询终端参数
   * @param {*} terminalNos 需要操作的车辆设备号数组
   * @param {*} params 子级指令和参数
   */
  GBQueryParameters(terminalNos, params, terminalType) {
    try {
      const objTemp = {
        command: 0x8104, // msgId,num类型
        params: params, // 发送内容
        terminalType: terminalType, // 发送内容
        terminalPhoneNos: terminalNos[0], // 需下发的手机号
        terminalDetail: terminalNos[1] // 需回显的信息
      };
      // console.log('查询终端参数',objTemp)
      this.SendSocketData(JSON.stringify(objTemp), "查询终端参数", "");
    } catch (error) {
      console.error("yh_sdp  GB_SetVehicleDate  error: " + error.message);
    }
  }

  /**
   * 部标设备指令-设置终端参数(整体，文本信息在SendCmdToCenter方法中写)
   * @param {*} terminalNos 需要操作的车辆设备号数组
   * @param {*} params 子级指令和参数
   */
  GBSetParameters(terminalNos, params, terminalType) {
    try {
      const objTemp = {
        command: 0x8103, // msgId,num类型
        params: params, // 发送内容
        terminalType: terminalType, // 发送内容
        terminalPhoneNos: terminalNos[0], // 需下发的手机号
        terminalDetail: terminalNos[1] // 需回显的信息
      };
      this.SendSocketData(JSON.stringify(objTemp), "设置终端参数");
    } catch (error) {
      console.error("yh_sdp  GBSetParameters  error: " + error.message);
    }
  }

  /**
   * 部标设备指令-查询终端参数-运营汇报策略配置
   * @param {*} terminalNos
   * @param {*} params
   * @param {*} terminalType
   */
  GBSetParametersConfig(terminalNos, params, terminalType) {
    try {
      const objTemp = {
        command: 0x8103, // msgId,num类型
        params: params, // 发送内容
        terminalType: terminalType, // 发送内容
        terminalPhoneNos: terminalNos[0], // 需下发的手机号
        terminalDetail: terminalNos[1] // 需回显的信息
      };
      this.SendSocketData(JSON.stringify(objTemp), "设置终端参数");
    } catch (error) {
      console.error("yh_sdp  GBSetParameters  error: " + error.message);
    }
  }

  /**
   * 部标设备指令-实时拍照指令
   * @param {*} terminalNos
   * @param {*} params
   * @param {*} terminalType
   */
  GBRealTimeTakePictures(terminalNos, params, terminalType) {
    try {
      const objTemp = {
        command: 0x8801, // msgId,num类型
        params: params, // 发送内容
        terminalType: terminalType, // 终端类型, 按照数据字典定义
        terminalPhoneNos: terminalNos[0], // 需下发的手机号
        terminalDetail: terminalNos[1] // 需回显的信息
      };
      this.SendSocketData(JSON.stringify(objTemp), "实时拍照");
    } catch (error) {
      console.error("yh_sdp  GBRealTimeTakePictures  error: " + error.message);
    }
  }

  /**
   * 部标设备指令-人工确认解除报警
   * @param {*} terminalNos
   * @param {*} params
   * @param {*} terminalType
   */
  GBArtificialRemoveAlarm(terminalNos, params, terminalType) {
    try {
      const objTemp = {
        command: 0x8203, // msgId,num类型
        params: params, // 发送内容
        terminalType: terminalType, // 终端类型, 按照数据字典定义
        terminalPhoneNos: terminalNos[0], // 需下发的手机号
        terminalDetail: terminalNos[1] // 需回显的信息
      };
      this.SendSocketData(JSON.stringify(objTemp), "人工确认解除报警");
    } catch (error) {
      console.error("yh_sdp  GBArtificialRemoveAlarm  error: " + error.message);
    }
  }

  /**
   * 部标设备指令-载重限高配置+视音频
   * @param {*} terminalNos
   * @param {*} params
   * @param {*} terminalType
   */
  GBLimitHeightConfig(terminalNos, params, terminalType) {
    try {
      const objTemp = {
        command: 0x8103, // msgId,num类型
        params: params, // 发送内容
        terminalType: terminalType, // 终端类型, 按照数据字典定义
        terminalPhoneNos: terminalNos[0], // 需下发的手机号
        terminalDetail: terminalNos[1] // 需回显的信息
      };
      this.SendSocketData(JSON.stringify(objTemp), "设置终端参数");
    } catch (error) {
      console.error("yh_sdp  GBArtificialRemoveAlarm  error: " + error.message);
    }
  }

  /**部标设备指令-adas参数设置 */
  GBAdasConfig(terminalNos, params, terminalType) {
    try {
      const objTemp = {
        command: 0x8103, // msgId,num类型
        params: params, // 发送内容
        terminalType: terminalType, // 终端类型, 按照数据字典定义
        terminalPhoneNos: terminalNos[0], // 需下发的手机号
        terminalDetail: terminalNos[1] // 需回显的信息
      };
      this.SendSocketData(JSON.stringify(objTemp), "设置终端参数");
    } catch (error) {
      console.error("yh_sdp  GBArtificialRemoveAlarm  error: " + error.message);
    }
  }

  /**
   * 部标设备指令-查询终端参数
   * @param {*} terminalNos 需要操作的车辆设备号数组
   * @param {*} params 子级指令和参数
   */
  GBQueryAlarmSeting(terminalNos, params, terminalType) {
    try {
      const objTemp = {
        command: 0x8104, // msgId,num类型
        params: params, // 发送内容
        terminalType: terminalType, // 发送内容
        terminalPhoneNos: terminalNos // 需下发的手机号
      };
      this.SendData(JSON.stringify(objTemp), "0xE7");
      console.log("*************查询", objTemp);
    } catch (error) {
      console.error("yh_sdp  GB_SetVehicleDate  error: " + error.message);
    }
  }

  /**
   * 部标设备指令-设置终端参数-设置终端参数报警屏蔽
   * @param {*} terminalNos 需要操作的车辆设备号数组
   * @param {*} params 子级指令和参数
   */
  GBSetAlarmSeting(terminalNos, params, terminalType) {
    try {
      const objTemp = {
        command: 0x8103, // msgId,num类型
        params: params, // 发送内容
        terminalType: terminalType, // 发送内容
        terminalPhoneNos: terminalNos[0], // 需下发的手机号
        terminalDetail: terminalNos[1] // 需回显的信息
      };
      // console.log('屏蔽报警下发指令',objTemp)
      this.SendData(JSON.stringify(objTemp), "0xE7");
    } catch (error) {
      console.error("yh_sdp  GBSetParameters  error: " + error.message);
    }
  }

  /**计价器控制 */
  GBSetAlarmSetingX(terminalNos, params, terminalType) {
    try {
      let objTemp = {
        command: 0x8b10, // msgId,num类型
        params: params, // 发送内容
        terminalType: terminalType, // 发送内容
        terminalPhoneNos: terminalNos[0], // 需下发的终端号
        terminalDetail: terminalNos[1], // 需回显的信息
        childMsgId: 0x0027
      };
      this.SendSocketData(
        JSON.stringify(objTemp),
        params === 1 ? "计价器解锁" : "计较器加锁",
        ""
      );
    } catch (error) {
      console.error("yh_sdp  GBSetParameters  error: " + error.message);
    }
  }
  /**计价器控制（昆博） */
  GBSetAlarmSetingKunBo(terminalNos, params, terminalType) {
    try {
      let objTemp = {
        command: 0x8103, // msgId,num类型
        params: params, // 发送内容
        terminalType: terminalType, // 发送内容
        terminalPhoneNos: terminalNos[0], // 需下发的终端号
        terminalDetail: terminalNos[1] // 需回显的信息
      };
      this.SendSocketData(JSON.stringify(objTemp), "设置终端参数");
    } catch (error) {
      console.error("yh_sdp  GBSetParameters  error: " + error.message);
    }
  }
  /**
   * 下发驾驶员信息-出租车系统-司机个数
   * @param {*} terminalNos
   * @param {*} terminalType
   */
  TaxiSendDriverNum(terminalNos, params, terminalType) {
    try {
      let objTemp = {
        command: 0x8b10, // msgId,num类型
        params: params, // 发送内容
        terminalType: terminalType, // 发送内容
        terminalPhoneNos: terminalNos[0], // 需下发的终端号
        terminalDetail: terminalNos[1], // 需回显的信息
        childMsgId: 0x9000
      };
      // console.log("司机个数",objTemp)
      this.SendSocketData(JSON.stringify(objTemp), "下发驾驶员个数", "");
    } catch (error) {
      console.error("yh_sdp  GBSetParameters  error: " + error.message);
    }
  }
  /**
   * 下发驾驶员信息-出租车系统-司机详情
   * @param {*} terminalNos
   * @param {*} terminalType
   */
  TaxiSendDriverDetails(terminalNos, params, terminalType) {
    try {
      let objTemp = {
        command: 0x8b10, // msgId,num类型
        params: params, // 发送内容
        terminalType: terminalType, // 发送内容
        terminalPhoneNos: terminalNos[0], // 需下发的终端号
        terminalDetail: terminalNos[1], // 需回显的信息
        childMsgId: 0x9001
      };
      this.SendSocketData(JSON.stringify(objTemp), "下发驾驶员详情", "");
      // console.log("司机详情",objTemp)
    } catch (error) {
      console.error("yh_sdp  GBSetParameters  error: " + error.message);
    }
  }

  /**
   * 部标设备指令 - 查询终端属性(查询终端版本信息)
   * @param {*} terminalNos 需要操作的车辆设备号数组
   * @param {*} params 子级指令和参数
   */
  GBQueryAttributes(terminalNos, terminalType) {
    try {
      const objTemp = {
        command: 0x8107, // msgId,num类型
        params: null, // 发送内容
        terminalType: terminalType, // 发送内容
        terminalPhoneNos: terminalNos[0], // 需下发的手机号
        terminalDetail: terminalNos[1] // 需回显的信息
      };
      this.SendSocketData(JSON.stringify(objTemp), "查询终端属性");
    } catch (error) {
      console.error("yh_sdp  GBQueryAttributes  error: " + error.message);
    }
  }

  /**
   * 部标设备指令 - 升级终端
   * @param {*} terminalNos 需要操作的车辆设备号数组
   * @param {*} params 子级指令和参数
   */
  GBTermainalUpdate(terminalNos, params, terminalType) {
    try {
      const objTemp = {
        command: 0x8899, // msgId,num类型
        params: params, // 发送内容
        terminalType: terminalType, // 发送内容
        terminalPhoneNos: terminalNos[0], // 需下发的手机号
        terminalDetail: terminalNos[1] // 需回显的信息
      };
      this.SendSocketData(JSON.stringify(objTemp), "终端升级");
    } catch (error) {
      console.error("yh_sdp  GBTermainalUpdate  error: " + error.message);
    }
  }

  /**
   * 部标设备指令 - 设置IC卡参数
   * @param {*} terminalNos 需要操作的车辆设备号数组
   * @param {*} params 子级指令和参数
   */
  GBSetICParam(terminalNos, params, terminalType) {
    try {
      const objTemp = {
        command: 0x8300, // msgId,num类型
        params: params, // 发送内容
        terminalType: terminalType, // 发送内容
        terminalPhoneNos: terminalNos[0], // 需下发的手机号
        terminalDetail: terminalNos[1] // 需回显的信息
      };
      this.SendData(JSON.stringify(objTemp), "0xE7");
    } catch (error) {
      console.error("yh_sdp  GBSetICParam  error: " + error.message);
    }
  }

  /**
   * 调度屏升级
   * @param {*} terminalNos 需要操作的车辆设备号数组
   * @param {*} params 子级指令和参数
   */
  GBDispatchingUpgrade(terminalNos, params, terminalType) {
    try {
      const objTemp = {
        command: 0x8d0a, // msgId,num类型
        params: params, // 发送内容
        terminalType: terminalType, // 发送内容
        terminalPhoneNos: terminalNos[0], // 需下发的手机号
        terminalDetail: terminalNos[1] // 需回显的信息
      };
      if (this.thisReceive != null) {
        this.thisReceive.sendUpgradeData(JSON.stringify(objTemp), "0xE7");
      }
    } catch (error) {
      console.error("yh_sdp  GBTermainalUpdate  error: " + error.message);
    }
  }
  /** *****************************************************查看视频指令************************************************************8 */

  /**
   * 实时音视频传输请求
   * @param {*} params
   * @param {*} isHD
   */
  GBRealVideoStreamReq(params, isHD, terminalType) {
    try {
      const param6 = isHD === "高清" ? 0 : 1;
      const reqParams = [
        baseConfig.videoBaseUrl, // 实时视频服务器IP地址
        params.port, // 实时视频服务器TCP端口号
        params.port, // 实时视频服务器UDP端口号
        Number(params.channel), // 逻辑通道号，详见JT/T 1076-2016表2（1：驾驶员 2：车辆正前方 3：车前门 4：车厢前部 5：车厢后部 6：车后门 7行李舱 8：车辆左侧 9：车辆右侧 10：车辆正后方 11：车厢中部 12：车中门 13：驾驶席车门）
        0, // 数据类型（0：音视频 1：视频 2：双向对讲 3：监听 4：中心广播 5：透传）
        param6 // 码流类型（0：主码流 1：子码流）
      ];
      const objTemp = {
        command: 0x9101, // msgId,num类型
        params: reqParams, // 发送内容
        terminalType: sessionStorage.getItem("system"), // 发送内容
        terminalPhoneNos: [params.device] // 需下发的手机号
      };
      console.log("real", objTemp);
      this.SendSocketData(JSON.stringify(objTemp), "实时音视频传输请求");
    } catch (error) {
      console.error("yh_sdp  GSMNO  error: " + error.message);
    }
  }
  /**
   * 实时音视频传输请求-ws-flv
   * @param {*} params
   * @param {*} isHD
   */
  GBRealVideoStreamReqWs(params, isHD, terminalType) {
    try {
      const param6 = isHD === "高清" ? 0 : 1;
      const reqParams = [
        baseConfig.basePortIp, // 实时视频服务器IP地址
        baseConfig.videoBasePort, // 实时视频服务器TCP端口号
        baseConfig.videoBasePort, // 实时视频服务器UDP端口号
        Number(params.channel), // 逻辑通道号，详见JT/T 1076-2016表2（1：驾驶员 2：车辆正前方 3：车前门 4：车厢前部 5：车厢后部 6：车后门 7行李舱 8：车辆左侧 9：车辆右侧 10：车辆正后方 11：车厢中部 12：车中门 13：驾驶席车门）
        0, // 数据类型（0：音视频 1：视频 2：双向对讲 3：监听 4：中心广播 5：透传）
        param6 // 码流类型（0：主码流 1：子码流）
      ];
      const objTemp = {
        command: 0x9101, // msgId,num类型
        params: reqParams, // 发送内容
        terminalType: sessionStorage.getItem("system"), // 发送内容
        terminalPhoneNos: [params.device] // 需下发的手机号
      };
      console.log("real", objTemp);
      this.SendSocketData(JSON.stringify(objTemp), "ws-实时音视频传输请求");
    } catch (error) {
      console.error("yh_sdp  GSMNO  error: " + error.message);
    }
  }
  /**实时监听 */
  GBListeningStreamReq(params) {
    try {
      const reqParams = [
        baseConfig.videoBaseUrl, // 实时视频服务器IP地址
        params.port, // 实时视频服务器TCP端口号
        params.port, // 实时视频服务器UDP端口号
        1, // 监听始终只有1号通道
        3, // 数据类型（0：音视频 1：视频 2：双向对讲 3：监听 4：中心广播 5：透传）
        1 // 码流类型（0：主码流 1：子码流）
      ];
      const objTemp = {
        command: 0x9101, // msgId,num类型
        params: reqParams, // 发送内容
        terminalType: sessionStorage.getItem("system"), // 发送内容
        terminalPhoneNos: [params.device] // 需下发的手机号
      };
      this.SendSocketData(JSON.stringify(objTemp), "实时音视频传输请求");
    } catch (error) {
      console.error("yh_sdp  GSMNO  error: " + error.message);
    }
  }

  /**
   * 视频测试
   */
  GBRealVideoStreamReqTest(params, isHD, terminalType) {
    try {
      const param6 = isHD === "高清" ? 0 : 1;
      const reqParams = [
        baseConfig.basePortIp, // 实时视频服务器IP地址
        params.port, // 实时视频服务器TCP端口号；本地：19922,测试：17019
        params.port, // 实时视频服务器UDP端口号
        Number(params.channel), // 逻辑通道号，详见JT/T 1076-2016表2（1：驾驶员 2：车辆正前方 3：车前门 4：车厢前部 5：车厢后部 6：车后门 7行李舱 8：车辆左侧 9：车辆右侧 10：车辆正后方 11：车厢中部 12：车中门 13：驾驶席车门）
        0, // 数据类型（0：音视频 1：视频 2：双向对讲 3：监听 4：中心广播 5：透传）
        param6 // 码流类型（0：主码流 1：子码流）
      ];
      const objTemp = {
        command: 0x9101, // msgId,num类型实时
        params: reqParams, // 发送内容
        terminalType: sessionStorage.getItem("system"), // 发送内容
        terminalPhoneNos: [params.device] // 需下发的手机号
      };
      this.SendSocketData(JSON.stringify(objTemp), "实时音视频传输请求");
    } catch (error) {
      console.error("yh_sdp  GSMNO  error: " + error.message);
    }
  }
  /**
   * 视频清晰度切换
   * 实时音视频传输控制(码流切换)
   * @param {*} params
   * @param {*} isHD
   */
  GBRealVideoStreamControl(params, isHD) {
    try {
      const param4 = isHD === "高清" ? 0 : 1;
      const reqParams = [
        Number(params.channel), // 逻辑通道号，详见JT/T 1076-2016表2（1：驾驶员 2：车辆正前方 3：车前门 4：车厢前部 5：车厢后部 6：车后门 7：行李舱 8：车辆左侧 9：车辆右侧 10：车辆正后方 11：	车厢中部 12：车中门 13：驾驶席车门）
        1, // 控制指令（0：关闭视音频传输请求 1：切换码流 2：暂停该通道所有流的发送 3：恢复暂停前流的发送 4：关闭双向对讲）
        0, // 关闭音视频类型（0：关闭音视频 1：关闭音频 2：关闭视频）
        param4 // 切换码流类型（0：主码流 1：子码流）
      ];
      const objTemp = {
        command: 0x9102, // msgId,num类型
        params: reqParams, // 发送内容
        terminalType: sessionStorage.getItem("system"), // 发送内容
        terminalPhoneNos: [params.device] // 需下发的手机号
      };
      this.SendSocketData(JSON.stringify(objTemp), "实时音视频视频清晰度切换");
    } catch (error) {
      console.error("yh_sdp  GSMNO  error: " + error.message);
    }
  }

  /**
   * 关闭全部实时视频请求
   * @param {*} params
   */
  GBRealVideoStreamControlAll(params) {
    try {
      const reqParams = [
        Number(params.channel), // 逻辑通道号，详见JT/T 1076-2016表2（1：驾驶员 2：车辆正前方 3：车前门 4：车厢前部 5：车厢后部 6：车后门 7：行李舱 8：车辆左侧 9：车辆右侧 10：车辆正后方 11：	车厢中部 12：车中门 13：驾驶席车门）
        0, // 控制指令（0：关闭视音频传输请求 1：切换码流 2：暂停该通道所有流的发送 3：恢复暂停前流的发送 4：关闭双向对讲）
        0, // 关闭音视频类型（0：关闭音视频 1：关闭音频 2：关闭视频）
        0 // 切换码流类型（0：主码流 1：子码流）
      ];
      const objTemp = {
        command: 0x9102, // msgId,num类型
        params: reqParams, // 发送内容
        terminalType: sessionStorage.getItem("system"), // 发送内容
        terminalPhoneNos: [params.device] // 需下发的手机号
      };
      // console.log('关闭全部实时视频请求',objTemp)
      this.SendSocketData(JSON.stringify(objTemp), "关闭全部实时视频请求");
    } catch (error) {
      console.error("yh_sdp  GSMNO  error: " + error.message);
    }
  }

  /**
   * 查询终端资源列表
   * @param {*} params
   */
  GBQueryTerminalResList(params) {
    try {
      const reqParams = [
        params.channel, // 逻辑通道号，0表示所有通道，详见JT/T 1076-2016表2（1：驾驶员 2：车辆正前方 3：车前门 4：车厢前部 5：车厢后部 6：车后门 7：行李舱 8：车辆左侧 9：车辆右侧 10：车辆正后方 11：车厢中部 12：车中门 13：驾驶席车门）
        params.begintime, // 开始时间（时间戳精度：秒）
        params.endtime, // 结束时间
        "", // 基础报警标志，与“部标指令-设置终端参数-设置报警屏蔽位”类似，一个或多个，‘|’相隔，无空格（紧急报警|超速报警|疲累报警|预警|GNSS故障报警|GNSS天线开路报警|GNSS天线短路报警|终端主电源欠压报警|终端主电源掉电报警|终端LCD故障报警|TTS模块故障报警|摄像头故障报警|当天累计驾驶超时报警|停车超时报警|进出区域报警|进出路线报警|路段行驶时间不足|路线偏离报警|车辆VSS故障报警|车辆油量异常报警|盗警|非法点火报警|非法移位报警|碰撞侧翻报警）
        "", // 扩展视频报警标志，一个或多个，‘|’相隔，无空格（视频信号丢失|视频信号遮挡|存储单元故障|其他视频设备故障|客车超员|异常驾驶|特殊报警录像达到存储阈值）
        3, // 音视频资源类型（0：音视频 1：音频 2：视频 3：视频或音视频）
        0, // 码流类型（0：所有码流 1：主码流 2：子码流）
        0 // 存储器类型（0：所有存储器 1：主存储器 2：灾备存储器）
      ];
      const objTemp = {
        command: 0x9205, // msgId,num类型
        params: reqParams, // 发送内容
        terminalType: sessionStorage.getItem("system"), // 发送内容
        terminalPhoneNos: [params.device] // 需下发的手机号
      };
      // console.log('查询终端资源列表(历史视频)',objTemp)
      this.SendSocketData(
        JSON.stringify(objTemp),
        "查询终端资源列表(历史视频)"
      );
    } catch (error) {
      console.error("yh_sdp  GSMNO  error: " + error.message);
    }
  }

  /**
   * 查询终端资源列表---2022.1过检--谢修改
   * @param {*} params
   */
  QueryTerminalHisList(params) {
    try {
      const reqParams = [
        params.channel, // 逻辑通道号，0表示所有通道，详见JT/T 1076-2016表2（1：驾驶员 2：车辆正前方 3：车前门 4：车厢前部 5：车厢后部 6：车后门 7：行李舱 8：车辆左侧 9：车辆右侧 10：车辆正后方 11：车厢中部 12：车中门 13：驾驶席车门）
        params.begintime, // 开始时间（时间戳精度：秒）
        params.endtime, // 结束时间
        params.alarmType, // 基础报警标志，与“部标指令-设置终端参数-设置报警屏蔽位”类似，一个或多个，‘|’相隔，无空格（紧急报警|超速报警|疲累报警|预警|GNSS故障报警|GNSS天线开路报警|GNSS天线短路报警|终端主电源欠压报警|终端主电源掉电报警|终端LCD故障报警|TTS模块故障报警|摄像头故障报警|当天累计驾驶超时报警|停车超时报警|进出区域报警|进出路线报警|路段行驶时间不足|路线偏离报警|车辆VSS故障报警|车辆油量异常报警|盗警|非法点火报警|非法移位报警|碰撞侧翻报警）
        "", // 扩展视频报警标志，一个或多个，‘|’相隔，无空格（视频信号丢失|视频信号遮挡|存储单元故障|其他视频设备故障|客车超员|异常驾驶|特殊报警录像达到存储阈值）
        params.videoType, // 音视频资源类型（0：音视频 1：音频 2：视频 3：视频或音视频）
        params.streamType, // 码流类型（0：所有码流 1：主码流 2：子码流）
        params.address // 存储器类型（0：所有存储器 1：主存储器 2：灾备存储器）
      ];
      const objTemp = {
        command: 0x9205, // msgId,num类型
        params: reqParams, // 发送内容
        terminalType: sessionStorage.getItem("system"), // 发送内容
        terminalPhoneNos: [params.device] // 需下发的手机号
      };
      this.SendSocketData(
        JSON.stringify(objTemp),
        "查询终端资源列表(历史视频)"
      );
    } catch (error) {
      console.error("yh_sdp  GSMNO  error: " + error.message);
    }
  }

  /**
   * 平台下发远程录像回放请求(*播放)
   * @param {*} device
   * @param {*} channel
   * @param {*} beginTime
   * @param {*} endTime
   * @param {*} resType
   * @param {*} streamType
   * @param {*} storageType
   * @param {*} number
   */
  GBHistoryStreamReq(
    device,
    channel,
    beginTime,
    endTime,
    resType,
    streamType,
    storageType,
    number
  ) {
    try {
      const system = sessionStorage.getItem("system");
      let reqParams = [];
      // if(system==='1'){
      //   //出租车系统
      //   reqParams = [
      //     baseConfig.videoBaseUrl, // 实时视频服务器IP地址
      //     baseConfig.taxiVideoHistoryPort, // 实时视频服务器TCP端口号，不使用TCP传输时填0
      //     baseConfig.taxiVideoHistoryPort, // 实时视频服务器UDP端口号，不使用UDP传输时填0
      //     channel, // 逻辑通道号，详见JT/T 1076-2016表2（1：驾驶员 2：车辆正前方 3：车前门 4：车厢前部 5：车厢后部 6：车后门 7：行李舱 8：车辆左侧 9：车辆右侧 10：车辆正后方 11：车厢中部 12：车中门 13：驾驶席车门）
      //     resType, // 音视频资源类型（0：音视频 1：音频 2：视频 3：视频或音视频）
      //     streamType, // 码流类型，如果此通道只传输音频，则填0（0：主码流或子码流 1：主码流 2：子码流）
      //     storageType, // 存储器类型（0：所有存储器 1：主存储器 2：灾备存储器）
      //     0, // 回放方式（0：正常回放 1：快进回放 2：关键帧快退回放 3：关键帧播放 4：单帧上传）
      //     number, // 快进或快退倍数（0：无效 1：1倍 2：2倍 3：4倍 4：8倍 5：16倍）
      //     beginTime, // 开始时间（回放方式为4时，该字段表示单帧上传时间）
      //     endTime // 结束时间（此字段为0时表示一直回放，回放方式为4时，该字段无效）
      //   ]
      // }else{
      reqParams = [
        baseConfig.videoBaseUrl, // 实时视频服务器IP地址
        baseConfig.videoHistoryPort, // 实时视频服务器TCP端口号，不使用TCP传输时填0
        baseConfig.videoHistoryPort, // 实时视频服务器UDP端口号，不使用UDP传输时填0
        channel, // 逻辑通道号，详见JT/T 1076-2016表2（1：驾驶员 2：车辆正前方 3：车前门 4：车厢前部 5：车厢后部 6：车后门 7：行李舱 8：车辆左侧 9：车辆右侧 10：车辆正后方 11：车厢中部 12：车中门 13：驾驶席车门）
        resType, // 音视频资源类型（0：音视频 1：音频 2：视频 3：视频或音视频）
        streamType, // 码流类型，如果此通道只传输音频，则填0（0：主码流或子码流 1：主码流 2：子码流）
        storageType, // 存储器类型（0：所有存储器 1：主存储器 2：灾备存储器）
        0, // 回放方式（0：正常回放 1：快进回放 2：关键帧快退回放 3：关键帧播放 4：单帧上传）
        number, // 快进或快退倍数（0：无效 1：1倍 2：2倍 3：4倍 4：8倍 5：16倍）
        beginTime, // 开始时间（回放方式为4时，该字段表示单帧上传时间）
        endTime // 结束时间（此字段为0时表示一直回放，回放方式为4时，该字段无效）
      ];
      // }

      const objTemp = {
        command: 0x9201, // msgId,num类型
        params: reqParams, // 发送内容
        terminalType: system, // 发送内容
        terminalPhoneNos: [device] // 需下发的手机号
      };
      console.log("history", objTemp);
      this.SendSocketData(
        JSON.stringify(objTemp),
        "平台下发远程录像回放请求(历史视频)"
      );
    } catch (error) {
      console.error("yh_sdp  GSMNO  error: " + error.message);
    }
  }
  //谢 2021.06.16新视频flv(回放)
  GBHistoryStreamReqNew(
    device,
    channel,
    beginTime,
    endTime,
    resType,
    streamType,
    storageType,
    number
  ) {
    try {
      const system = sessionStorage.getItem("system");
      let reqParams = [];
      reqParams = [
        baseConfig.basePortIp, // 实时视频服务器IP地址
        baseConfig.historyPortVideo, // 实时视频服务器TCP端口号，不使用TCP传输时填0；本地：19911,测试：17019
        baseConfig.historyPortVideo, // 实时视频服务器UDP端口号，不使用UDP传输时填0；本地：19911
        channel, // 逻 通道号，详见JT/T 1076-2016表2（1：驾驶员 2：车辆正前方 3：车前门 4：车厢前部 5：车厢后部 6：车后门 7：行李舱 8：车辆左侧 9：车辆右侧 10：车辆正后方 11：车厢中部 12：车中门 13：驾驶席车门）
        resType, // 音视频资源类型（0：音视频 1：音频 2：视频 3：视频或音视频）
        streamType, // 码流类型，如果此通道只传输音频，则填0（0：主码流或子码流 1：主码流 2：子码流）
        storageType, // 存储器类型（0：所有存储器 1：主存储器 2：灾备存储器）
        0, // 回放方式（0：正常回放 1：快进回放 2：关键帧快退回放 3：关键帧播放 4：单帧上传）
        number, // 快进或快退倍数（0：无效 1：1倍 2：2倍 3：4倍 4：8倍 5：16倍）
        beginTime, // 开始时间（回放方式为4时，该字段表示单帧上传时间）
        endTime // 结束时间（此字段为0时表示一直回放，回放方式为4时，该字段无效）
      ];
      const objTemp = {
        command: 0x9201, // msgId,num类型
        params: reqParams, // 发送内容
        common: "self",
        terminalType: system, // 发送内容
        terminalPhoneNos: [device] // 需下发的手机号
      };
      this.SendSocketData(
        JSON.stringify(objTemp),
        "平台下发远程录像回放请求(历史视频)"
      );
    } catch (error) {
      console.error("yh_sdp  GSMNO  error: " + error.message);
    }
  }
  /**
   * 平台下发远程录像回放控制(停止)
   * @param {*} params
   */
  GBHistoryStreamControl(params) {
    try {
      const reqParams = [
        params.channel, // 逻辑通道号，详见JT/T 1076-2016表2（1：驾驶员 2：车辆正前方 3：车前门 4：车厢前部 5：车厢后部 6：车后门 7：行李舱 8：车辆左侧 9：车辆右侧 10：车辆正后方 11：车厢中部 12：车中门 13：驾驶席车门）
        2, // 回放控制（0：开始回放 1：暂停回放 2：结束回放 3：快进回放 4：关键帧快退回放 5：拖动回放 6：关键帧播放
        1, // 快进或快退倍数，回放控制为3或4时，此字段有效，否则置0（0：无效 1：1倍 2：2倍 3：4倍 4：8倍 5：16倍）
        0 // 回放控制为5时，此字段有效，时间戳精度：秒
      ];
      const objTemp = {
        command: 0x9202, // msgId,num类型
        params: reqParams, // 发送内容
        terminalType: sessionStorage.getItem("system"), // 发送内容
        terminalPhoneNos: [params.device] // 需下发的手机号
      };
      this.SendSocketData(JSON.stringify(objTemp), "历史视频关闭请求");
    } catch (error) {
      console.error("yh_sdp  GSMNO  error: " + error.message);
    }
  }
  GBHistoryStreamControlNew(params) {
    try {
      const reqParams = [
        params.channel, // 逻辑通道号，详见JT/T 1076-2016表2（1：驾驶员 2：车辆正前方 3：车前门 4：车厢前部 5：车厢后部 6：车后门 7：行李舱 8：车辆左侧 9：车辆右侧 10：车辆正后方 11：车厢中部 12：车中门 13：驾驶席车门）
        2, // 回放控制（0：开始回放 1：暂停回放 2：结束回放 3：快进回放 4：关键帧快退回放 5：拖动回放 6：关键帧播放
        1, // 快进或快退倍数，回放控制为3或4时，此字段有效，否则置0（0：无效 1：1倍 2：2倍 3：4倍 4：8倍 5：16倍）
        0 // 回放控制为5时，此字段有效，时间戳精度：秒
      ];
      const objTemp = {
        command: 0x9202, // msgId,num类型
        params: reqParams, // 发送内容
        common: "self",
        terminalType: sessionStorage.getItem("system"), // 发送内容
        terminalPhoneNos: [params.device] // 需下发的手机号
      };
      // console.log('下发指令关流',objTemp)
      this.SendSocketData(JSON.stringify(objTemp), "历史视频关闭请求");
    } catch (error) {
      console.error("yh_sdp  GSMNO  error: " + error.message);
    }
  }

  /**
   * 平台下发远程录像回放控制(拖动回放)
   * @param {*} device
   * @param {*} channel
   * @param {*} number
   */
  GBHistoryStreamSpeedControl(device, channel, number) {
    try {
      const reqParams = [
        channel, // 逻辑通道号，详见JT/T 1076-2016表2（1：驾驶员 2：车辆正前方 3：车前门 4：车厢前部 5：车厢后部 6：车后门 7：行李舱 8：车辆左侧 9：车辆右侧 10：车辆正后方 11：车厢中部 12：车中门 13：驾驶席车门）
        5, // 回放控制（ 3：快进回放 4：关键帧快退回放 5：拖动回放 6：关键帧播放
        0, // 快进或快退倍数，回放控制为3或4时，此字段有效，否则置0（0：无效 1：1倍 2：2倍 3：4倍 4：8倍 5：16倍）
        number // 回放控制为5时，此字段有效，时间戳精度：秒
      ];
      const objTemp = {
        command: 0x9202, // msgId,num类型
        params: reqParams, // 发送内容
        terminalType: sessionStorage.getItem("system"), // 发送内容
        terminalPhoneNos: [device] // 需下发的手机号
      };
      this.SendSocketData(JSON.stringify(objTemp), "历史视频控制请求");
    } catch (error) {
      console.error("yh_sdp  GSMNO  error: " + error.message);
    }
  }

  /**
   * 文件上传(主要是历史视频)
   * @param {*} device
   * @param {*} channel
   * @param {*} beginTime
   * @param {*} endTime
   * @param {*} resType
   * @param {*} streamType
   * @param {*} storageType
   */
  downLoadHistoryFile(
    device,
    channel,
    beginTime,
    endTime,
    resType,
    streamType,
    storageType
  ) {
    try {
      // 指令content : 逻辑通道,开始时间, 结束时间, 音视频资源类型, 码流类型, 存储类型, 执行任务条件(wifi|lan|4G),用户id
      const reqParams = [
        channel, // 逻辑通道号，详见JT/T 1076-2016表2（1：驾驶员 2：车辆正前方 3：车前门 4：车厢前部 5：车厢后部 6：车后门 7：行李舱 8：车辆左侧 9：车辆右侧 10：车辆正后方 11：车厢中部 12：车中门 13：驾驶席车门）
        beginTime, // 开始时间（回放方式为4时，该字段表示单帧上传时间）
        endTime, // 结束时间（此字段为0时表示一直回放，回放方式为4时，该字段无效）
        resType, // 音视频资源类型（0：音视频 1：音频 2：视频 3：视频或音视频）
        streamType, // 码流类型，如果此通道只传输音频，则填0（0：主码流或子码流 1：主码流 2：子码流）
        storageType, // 存储器类型（0：所有存储器 1：主存储器 2：灾备存储器）
        "wifi|lan|4G" // 执行任务条件(wifi|lan|4G)
      ];
      // console.log('reqParams',reqParams)
      const objTemp = {
        command: 0x9206, // msgId,num类型
        params: reqParams, // 发送内容
        terminalType: sessionStorage.getItem("system"), // 发送内容
        terminalPhoneNos: [device] // 需下发的手机号
      };
      this.SendSocketData(JSON.stringify(objTemp), "历史视频上传请求");
    } catch (error) {
      console.error("yh_sdp  GSMNO  error: " + error.message);
    }
  }

  /**
   * 附件请求指令
   */
  attachmentRequest(
    device,
    alarmNo,
    alarmSignTerminalId,
    alarmSignTime,
    fileNo,
    alarmSignNum
  ) {
    try {
      const reqParams = [
        alarmNo,
        alarmSignTerminalId,
        alarmSignTime,
        fileNo,
        alarmSignNum
        // "params": ["报警号", "设备号", "报警时间", "报警序号", "附件数"]
      ];
      // console.log('reqParams',reqParams)
      const objTemp = {
        command: 0x9208, // msgId,num类型
        params: reqParams, // 发送内容
        terminalType: sessionStorage.getItem("system"), // 发送内容
        terminalPhoneNos: [device] // 需下发的手机号
      };
      // console.log('附件请求指令',objTemp)
      this.SendSocketData(JSON.stringify(objTemp), "附件请求指令");
    } catch (error) {
      console.error("yh_sdp  GSMNO  error: " + error.message);
    }
  }

  /**
   * 查岗应答
   */
  OnInspectionResponse(infoId, infoContent, objectId, objectType) {
    try {
      const reqParams = {
        infoId, //信息id
        infoContent, //应答内容
        objectId, //查岗对象id
        objectType //查岗对象类型
      };
      const objTemp = {
        type: 0x1301, // msgId,num类型
        data: reqParams, // 发送内容
        terminalType: sessionStorage.getItem("system"), // 发送内容
        platform: "platform"
      };
      // console.log('查岗',objTemp)
      this.SendData(JSON.stringify(objTemp), "0xEC");
    } catch (error) {
      console.error("yh_sdp  GSMNO  error: " + error.message);
    }
  }

  /**
   * 福建报警督办应答
   */
  OnoVerseeResponse(params, result) {
    try {
      const reqParams = { ...params, result };
      const objTemp = {
        type: 0x1401, // msgId,num类型
        data: reqParams, // 发送内容
        terminalType: sessionStorage.getItem("system"), // 发送内容
        platform: "platform"
      };
      // console.log('督办',objTemp)
      this.SendData(JSON.stringify(objTemp), "0xF6");
    } catch (error) {
      console.error("yh_sdp  GSMNO  error: " + error.message);
    }
  }

  /**
   * 福建平台查岗
   */
  OnInspectionResponseFuJian(infoId, infoContent, objectId, objectType, msgNo) {
    try {
      const reqParams = {
        infoId, //信息id
        infoContent: JSON.stringify(infoContent), //应答内容
        objectId, //查岗对象id
        objectType, //查岗对象类型
        msgNo
      };
      const objTemp = {
        type: 0x9999, // msgId,num类型
        data: reqParams, // 发送内容
        terminalType: sessionStorage.getItem("system"), // 发送内容
        platform: "platform"
      };
      // console.log('福建平台查岗',objTemp)
      this.SendData(JSON.stringify(objTemp), "0xF3");
    } catch (error) {
      console.error("yh_sdp  GSMNO  error: " + error.message);
    }
  }

  GBQueryChangeLine(terminalNos, params, terminalType) {
    try {
      const objTemp = {
        // command: 35737, // msgId,num类型
        command: 0x8b99, // msgId,num类型
        params: params, // 发送内容
        terminalType: terminalType, // 发送内容
        terminalPhoneNos: [terminalNos] // 需下发的手机号
      };
      this.thisReceive.sendUpgradeData(JSON.stringify(objTemp), "0xE7");
      console.log("*************查询GBQueryChangeLine", objTemp);
    } catch (error) {
      console.error("yh_sdp  GB_SetVehicleDate  error: " + error.message);
    }
  }

  /**
   * 查询车辆行驶记录
   *@param {Array} terminalNos 需要操作的车辆设备号数组
   */
  GBQueryCarDrivingRecords(terminalNos, params, terminalType) {
    try {
      const objTemp = {
        command: 0x8700, // msgId,num类型
        params: params, // 发送内容
        terminalType: terminalType, // 发送内容
        terminalPhoneNos: terminalNos[0], // 需下发的手机号
        terminalDetail: terminalNos[1] // 需回显的信息
      };
      this.SendSocketData(JSON.stringify(objTemp), "查询车辆行驶记录");
    } catch (error) {
      console.error("yh_sdp  GBSetParameters  error: " + error.message);
    }
  }
}
